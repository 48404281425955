export const QUIZ_REQUEST = 'QUIZ_REQUEST'
export const QUIZ_SUCCESS = 'QUIZ_SUCCESS'
export const QUIZ_FAILURE = 'QUIZ_FAILURE'
export const QUIZ_FINISHED = 'QUIZ_FINISHED'
export const QUIZ_TIMEOUT_SUBMIT = 'QUIZ_TIMEOUT'
export const QUIZ_SUBMIT = 'QUIZ_SUBMIT'
export const QUIZ_RESET = 'QUIZ_RESET'
export const QUIZ_RETRY_REQUEST = 'QUIZ_RETRY_REQUEST'
export const QUIZ_RETRY_SUCCESS = 'QUIZ_RETRY_SUCCESS'

const initialState = {
  loading: false,
  finished: false,
  redirect_url: null,
  questions: [],
  error: '',
  sending: false,
  success: false,
  retry_success: false,
  nextToken: null
}

export default function desktop(state = initialState, { type, payload }) {
  switch (type) {
    case QUIZ_REQUEST:
      return {
        ...state,
        loading: true,
        error: '',
      }
    case QUIZ_TIMEOUT_SUBMIT:
      return {
        loading: true,
        sending: true,
        success: false
      }
    case QUIZ_SUCCESS:
      return {
        ...state,
        loading: false,
        sending: false,
        success: true,
        finished: false,
        questions: payload,
      }
    case QUIZ_SUBMIT:
      return {
        ...state,
        loading: true,
        sending: true
      }
    case QUIZ_FINISHED:
      return {
        ...state,
        loading: false,
        finished: true
      }
    case QUIZ_FAILURE:
      return {
        ...state,
        loading: false,
        sending: false,
        success: false,
        error: payload,
      }
    case QUIZ_RESET:
      return initialState
    case QUIZ_RETRY_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case QUIZ_RETRY_SUCCESS:
      return {
        ...state,
        retry_success: true,
        loading: false,
      }
    default:
      return state
  }
}

export function fetchQuestions(payload) {
  return {
    type: QUIZ_REQUEST,
    payload,
  }
}


export function submitAnswers(payload) {
  return {
    type: QUIZ_SUBMIT,
    payload,
  }
}

export function retryQuiz(payload) {
  return {
    type: QUIZ_RETRY_REQUEST,
    payload,
  }
}

export function resetQuiz(payload) {
  return {
    type: QUIZ_RESET,
    payload,
  }
}


export function timeoutQuiz(payload) {
  return {
    type: QUIZ_TIMEOUT_SUBMIT,
    payload,
  }
}


