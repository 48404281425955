import React, { useState, useEffect } from 'react'
import { Provider } from 'react-redux'
import { ThemeProvider } from 'styled-components'
import { ThemeProvider as MaterialProvider } from '@material-ui/styles'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { PersistGate } from 'redux-persist/integration/react'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import * as Sentry from '@sentry/browser'
import { ConnectedRouter } from 'connected-react-router'
import moment from 'moment'
import 'moment/locale/pt-br'
import { Helmet } from 'react-helmet'
import * as faceapi from 'face-api.js'

import { store, persistor, history } from './store'
import { theme, materialTheme } from '@valid/config'
import './index.css'
import Routes from './routes'

moment.locale('pt-br')
const favicon = `assets/${process.env.REACT_APP_THEME}/favicon.ico`

if (['production', 'staging'].includes(process.env.REACT_APP_ENV)) {
  Sentry.init({
    dsn:
      'https://aa5186658d254d8389479f274b0035e9@o277483.ingest.sentry.io/5594696',
  })
}

export default function () {
  const [modelsLoaded, setModelsLoaded] = useState(false)

  useEffect(() => {
    if (!modelsLoaded) {
      const MODEL_URL = process.env.PUBLIC_URL + '/models'

      Promise.all([
        faceapi.nets.tinyFaceDetector.loadFromUri(MODEL_URL),
        faceapi.nets.faceLandmark68Net.loadFromUri(MODEL_URL),
        faceapi.nets.faceRecognitionNet.loadFromUri(MODEL_URL),
        faceapi.nets.faceExpressionNet.loadFromUri(MODEL_URL),
      ]).then(() => setModelsLoaded(true))
    }
  }, [modelsLoaded])

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider theme={theme}>
          <MaterialProvider theme={materialTheme}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <ToastContainer
                position="bottom-left"
                autoClose={5000}
                hideProgressBar
                newestOnTop
              />
              <ConnectedRouter history={history}>
                <Helmet>
                  <link
                    id="favicon"
                    rel="icon"
                    href={favicon}
                    type="image/x-icon"
                  />
                </Helmet>
                <Routes />
              </ConnectedRouter>
            </MuiPickersUtilsProvider>
          </MaterialProvider>
        </ThemeProvider>
      </PersistGate>
    </Provider>
  )
}
