import React from 'react'
import { useSelector } from 'react-redux'
import { Route, useHistory } from 'react-router-dom'

import Container from './styles'

// const logo = `assets/${process.env.REACT_APP_THEME}/logo.png`
const logo = "https://static-images-inovamind.s3.us-east-1.amazonaws.com/sa/logo.png"

export default ({ component: Component, parent: Parent, child, redirectlogged=true, ...rest }) => {
  const { token } = useSelector((state) => state.token)
  const history = useHistory()

  if (token && token.length && redirectlogged) {
      history.replace('/dashboard')
  }

  if (child){
    return <Parent component={child} {...rest} />
  }

  return (
    <Route
      {...rest}
      render={(matchProps) => (
        <Container>
          <main>
            <div className="brand">
              <img src={logo} alt="" className="brand__logo" />

            </div>
            <Component {...matchProps} />
          </main>
        </Container>
      )}
    />
  )
}
