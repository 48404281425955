import styled from 'styled-components'
import { motion } from 'framer-motion'

export default styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;

  .text {
    font-size: 1.4rem;
    font-weight: 600;
    margin-bottom: 1rem;
    color: ${(props) => props.theme.palette.gray[500]};
  }

  .counter {
    font-size: 2rem;
    font-weight: 600;
    color: ${(props) => props.theme.palette.gray[700]};
  }

  .success {
    margin: 4rem 0;
  }
`
