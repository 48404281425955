import { call, put } from 'redux-saga/effects'

import { DASHBOARD_SUCCESS, DASHBOARD_FAILURE } from 'store/reducers/dashboard'
import { get } from 'service/api'

export function* handleFetchPage(action) {
  const { token, page, name, cpf } = action.payload
  const res = yield call(get, {
    url: `/dashboard/${page}?name=${name}&cpf=${cpf}`,
    token,
  })
  if (res.status === 200) {
    yield put({
      type: DASHBOARD_SUCCESS,
      payload: { list: res.data.items, pages: res.data.total_pages },
    })
  } else {
    yield put({
      type: DASHBOARD_FAILURE,
    })
  }
}
