import React, { useState, useEffect } from 'react'
import { Formik, Field, Form} from 'formik'
import * as Yup from 'yup'
import { useSelector, useDispatch} from 'react-redux'
import { toast } from 'react-toastify'
import { CircularProgress, FormControlLabel, Radio, RadioGroup } from '@material-ui/core'
import { useLocation } from "react-router-dom";
import { useParams } from "react-router";
import { orange } from '@material-ui/core/colors';

import Container, { FailContainer } from './styles'

import {
  Button,
  Icons,
  ButtonsContainer,
  TargetSubtitle,
  Title
} from '@valid/components'
import { verifyTokenRole } from 'config/tokenRouter'
import { post, get } from 'service/api'
import { setToken } from 'store/reducers/token'

const buttonLabels  = [
  {id: 1, label: "Autorizo"},
  {id: 2, label: "Não Autorizo"},
  {id: 3, label: "Desconheço"},
  {id: 4, label: "Responder Quiz e Autorizar"}
]

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function formatDate(dateStr) {
  let formatted = null
  if(dateStr && dateStr.replace(' ', '') !== '') {
    const date = new Date(dateStr)
    let day = '0' + (date.getDate())
    let year = date.getFullYear()
    let month = '0' + (date.getMonth() + 1)
    day = day.substr(day.length-2);
    month = month.substr(month.length-2);
    formatted = day + "/" + month + "/" + year;
  }
  return formatted
}

export default function ({ location, history }) {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState('')
  const [verified, setVerified] = useState(false)
  const [showMessageQuiz, setShowMessageQuiz] = useState(false)
  const [currentName, setCurrentName] = useState('')
  const [createDate, setCreateDate] = useState(null)
  const [request, setRequest] = useState('')
  const [quizRequired, setQuizRequired] = useState(null)
  const { service, requestId } = useParams()
  const query = useQuery()
  const dispatch = useDispatch()
  const [option, setOption] = useState(null)

  const tokenVerify = query.get("token")
  const serviceName = service.toLowerCase()

  useEffect(() => {
    if(!verified) {
      setVerified(true)
      verifyToken(null)
    }
  }, [verified])

  const verifyToken = async (confirmation) => {
    setLoading(true)
    let isvalid = service === 'email' || service === 'sms'
    let errorMessage = "Solicitação de Resgate Inválida"
    
    if(isvalid) {
      const res = await post({
        url: `/request/${requestId}/verify`,
        token: false,
        data: {
          service: serviceName,
          token: tokenVerify,
          confirmation
        }
      })
      if(res.status === 200){
        const { name, create_at, quiz, finished} = res.data

        if(finished) {
          return history.replace(`/confirmation/${requestId}/success`)
        }

        let formatted = formatDate(create_at)
        setRequest(res.data)
        setQuizRequired(quiz)
        setCreateDate(formatted)
        setCurrentName(name)

      } else if(res.data.error == 'REQUEST_ALREADY_FINISHED') {
        return history.replace(`/confirmation/${requestId}/finished`)
      } else if(res.status === 500){
        isvalid = false
      } else if(res.data.error == 'REQUEST_NOT_FOUND') {
        isvalid = false
      } else if(res.data.error == 'INVALID_TOKEN') {
        isvalid = false
      } else if(res.data.error == 'OPERATION_NOT_ALLOWED') {
        isvalid = false
      }
    }

    setLoading(false)

    if (!isvalid) {
      return history.replace(`/confirmation/${requestId}/fail`)
    }
  }
  

  const handleBack = () => {
    setLoading(true)
    //history.replace({pathname: `/dashboard`})
    setLoading(false)
  }

  async function handleGotoQuiz() {
    history.replace(
      `/quiz/${requestId}?service=${serviceName}&token=${tokenVerify}`
    )
  }

  async function handleSubmit() {
    setLoading(true)
    if(quizRequired && option == 0) {
      setShowMessageQuiz(true)
    } else {
      verifyToken(option)
    }
    setLoading(false)
  }

  async function handleChange(e){
    const index = e.target.value
    setOption(index)
  }

  return !showMessageQuiz ? (
    !loading? (
    <Container
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.4 }}
    >
      <center><Title className='title'>Confirmação da Solicitação</Title></center>
      
      <TargetSubtitle className='target-subtitle'>
        Informamos que {createDate && `em ${createDate}` || 'nesta data'} a SulAmérica recebeu 
        movimentação/alteração do seu plano de previdência ou seguro de vida.
      </TargetSubtitle>
      <TargetSubtitle className='target-subtitle'>
        Para a sua segurança, pedimos a sua confirmação. 
      </TargetSubtitle>

      <Formik
        onSubmit={handleSubmit}
        enableReinitialize={true}
      >
        {(props) => {
          const { values } = props

          return (
            <Form
              className="form"
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleSubmit()
                }
              }}
            >

                <RadioGroup onChange={handleChange}>
                  <FormControlLabel 
                      value="0" 
                      className="radio-option green"
                      control={<Radio />}
                      label="Tenho Conhecimento e Autorizo" />
                  {quizRequired && option == 0 &&
                      <p className="quiz-alert">
                      (Para sua segurança, você será redirecionado para um Quiz)
                      </p>}
                  <FormControlLabel 
                      value="1" 
                      control={<Radio />}
                      className="radio-option red"
                      label="Não Tenho Conhecimento e Não autorizo" />
                  <FormControlLabel
                      value="2" 
                      control={<Radio />}
                      className="radio-option black" 
                      label={`Não sou ${currentName}.`} />
                </RadioGroup>


              {error && <p className="error">{error}</p>}
                
              {option &&
                <ButtonsContainer>
                  <Button
                    onClick={() => handleSubmit()}
                    variant="primary"
                    fullWidth
                  >
                    {quizRequired && option == 0 ?
                      buttonLabels[3].label
                      : buttonLabels[option].label}
                  </Button>
                </ButtonsContainer>
              }
            </Form>
          )
        }}
      </Formik>
    </Container>
    ) : (
      <Container
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.1 }}
      >
        <div className="loading">
          <div className="loading__container">
            <CircularProgress />
          </div>
        </div>
      </Container>
    )
  ) : (
    <FailContainer
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.4 }}
    >
      <h1>Quiz</h1>
      <h4>O quiz apresentará perguntas que devem ser 
        respondidas para validar a sua solicitação.</h4>

      <div className="hint">
        <Icons icon="light" />
        <p>Leia atentamente as questões e responda antes do tempo terminar.</p>
      </div>
      <Button variant="primary" onClick={handleGotoQuiz} loading={loading}>
        Iniciar Quiz
      </Button>
    </FailContainer>
  )
}
