import styled from 'styled-components'
import { motion } from 'framer-motion'

export default styled(motion.div)`

  .title {
    margin-bottom: 8px;
  }

  .target-subtitle {
    margin-bottom: 8px;
    color: black;
  }

  .radio-option { }

  .MuiTypography-body1 {
    font-size: 1.4rem !important;
    font-weight: 500 !important;
  }

  .MuiRadio-colorSecondary.Mui-checked {
    color: #f58236;
  }

  .quiz-alert { }

  .green { color: #0A0; }
  .red { color: #A00; }
  .black { color: #000; }

  .form {
    section {
      margin: 4rem 0;
    }

    .error {
      text-align: center;
      font-size: 1.2rem;
      font-weight: 600;
      color: #e31010;
      margin-bottom: 4rem;
    }
  }

  .loading {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.8);

    &__container {
      background-color: white;
      padding: 1.5rem;
      border-radius: 50%;

      svg {
        height: 4rem;
        width: 4rem;
      }
    }
`

export const FailContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  h1 {
    font-size: 2.4rem;
    color: ${(props) => props.theme.palette.primary};
    margin-bottom: 2rem;
  }

  h4 {
    font-size: 1.6rem;
    font-weight: 600;
    color: ${(props) => props.theme.palette.gray[600]};
    margin-bottom: 2rem;
  }

  .hint {
    border: 1px solid ${(props) => props.theme.palette.gray[200]};
    border-radius: 0.8rem;
    padding: 1rem;
    margin: 0 2rem;
    margin-bottom: 4rem;

    svg {
      width: 2.4rem;
      height: 2.4rem;
      margin-bottom: 1rem;
      color: #fcba03;
    }

    p {
      font-size: 1.4rem;
    }
  }
`

