import React from 'react'
import Select from 'react-select'
import styled from 'styled-components'

import Container from './styles'

const CustomSelect = styled(Select)`
  .customSelect {
    &__control {
      background-color: white;

      &--is-disabled {
        background-color: #f2f2f2;
      }
    }

    &__value-container {
    }

    &__indicator-separator {
      width: 0;
    }

    &__control,
    &__control:hover {
      border: transparent;
      box-shadow: none;
    }
  }

  .select {
    &__container--error {
      border-color: ${(props) => props.theme.palette.error};
    }
  }
`

export default function (props) {
  const { label, field, form, labeltype, options, onSelect } = props
  const { errors, values, setFieldValue } = form
  const { name } = field

  function handleChange(value) {
    setFieldValue(name, value.value)
    if (onSelect) onSelect(value)
  }

  return (
    <Container>
      <div className='select'>
        <div className={labeltype === 'inline' ? 'select--inline' : ''}>
          <label
            className={`select__label ${errors[name] ? 'select__label--error' : ''
              }`}
            htmlFor={name}
          >
            {label}
          </label>
          <div
            className={`select__container ${errors[name] ? 'select__container--error' : ''
              }`}
          >
            <CustomSelect
              options={options}
              {...props}
              {...field}
              className="select__input"
              id={name}
              onChange={handleChange}
              placeholder=""
              classNamePrefix="customSelect"
              value={props.options.filter(option => option.value === values[name])}
            />
          </div>
        </div>

        <div className='select__error'>{errors[name]}</div>
      </div>
    </Container>
  )
}
