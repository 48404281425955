import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router'

import { FailContainer } from './styles'
import { Button, Icons } from '@valid/components'
import { resetQuiz } from 'store/reducers/quiz'

export default function ({ history }) {
  const { token } = useSelector((state) => state.token)
  const { requestId } = useParams()
  const quiz = useSelector((state) => state.quiz)
  const dispatch = useDispatch()

  const { loading, error, finished } = quiz

  function handleRetry() {
    history.replace(`/service/selection/${requestId}`)
  }

  useEffect(() => {
    dispatch(resetQuiz())
  }, [])
  return (
    <FailContainer
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.4 }}
    >
      <h1>Processo em análise!</h1>
      <br/>
      <h4> Pedimos que aguarde o nosso contato telefônico.</h4>
    </FailContainer>
  )
}
