export const validation = {
  companySelected: 'Empresa já selecionada',
  invalidCpf: 'CPF inválido',
  invalidCnpj: 'CNPJ inválido',
  invalidEmail: 'Email inválido',
  invalidPassword: 'Senha inválida',
  invalidPhone: 'Número inválido',
  maxPasswordLength: "A senha é muito grande",
  minPasswordLength: "A senha deve conter pelo menos 6 caracteres",
  passwordHint:
  'A senha precisa ter pelo menos 1 letra maiuscula, 1 minúscula, 1 número e um caracter especial, no mínimo 10 caracteres e caracteres iguais não podem se repetir por mais de 3 vezes',
  passwordNotMatch: 'As senhas devem ser iguais',
  required: 'Campo obrigatório',
}

// TODO: Mapear status de mensagens de telas
const response_messages = {
  auth: {
    login: {
      401: 'Email / Nome de usuário ou email inválidos',
    },
    password: {
      401: 'Token de recuperação inválido',
      400: 'A nova senha deve ser diferente da atual',
    },
    verify: {
      401: 'Token inválido',
    },
  },
  signature: {
    verify: {
      401: 'Link expirado',
      400: 'Link inválido',
    },
  },
}

export function returnResponseMessage(resource, action, status) {
  if (status === 500)
    return 'Houve um erro interno no servidor. Tente novamente mais tarde.'
  const message = response_messages[resource][action][status]
  if (message) {
    return message
  }
  return 'Houve um erro inesperado'
}
