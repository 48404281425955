import styled from 'styled-components'
import { motion } from 'framer-motion'

export default styled(motion.div)`
  .codeContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 4rem 0;

    input {
      border: 1px solid ${(props) => props.theme.palette.gray[500]};
      font-size: 2.8rem;
      height: 5rem;
      width: 5rem;
      text-align: center;
      border-radius: 0.4rem;
      -webkit-appearance: none;
      outline: none;

      &:not(:first-child) {
        margin-left: 3rem;
      }
    }
  }

  .error {
    text-align: center;
    font-size: 1.2rem;
    font-weight: 600;
    color: #e31010;
    margin-bottom: 4rem;
  }

  .hint {
    font-size: 1rem;
    color: ${(props) => props.theme.palette.gray[600]};
    width: 100%;
    text-align: center;
    display: block;
  }

  .resend_sms {
    font-size: 1.2rem;
    font-weight: 600;
    color: ${(props) => props.theme.palette.gray[500]};
    display: flex;
    margin-top: 2rem;
    justify-content: center;

    .countdown_timer {
      padding: 0 0.5rem;
    }
  }

  .resend_sms_button {
    button {
      margin-top: 2rem;
      margin-bottom: 0;
      padding: 1rem 3rem;
      font-size: 1.4rem;
      font-weight: 500;
      border: 0;
      background-color: ${(props) => props.theme.palette.gray[300]};
      outline: none;
      cursor: pointer;
      color: black;
    }
  }

  .form {
    width: 35rem;
    color: #1d2d72;

    &__link_container {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 2rem;
    }

    &__link {
      color: ${(props) => props.theme.palette.primary};
      font-size: 1.1rem;
      text-transform: uppercase;
      text-decoration: none;
      background-color: transparent;
      border: none;
      outline: none;
      cursor: pointer;
    }

    &__error {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 1.5rem;
      font-size: 1.2rem;
      font-weight: 600;
      color: ${(props) => props.theme.palette.error};
    }
  }
`
