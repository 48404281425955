import React, { useState } from 'react'

import Container from './styles'
import Icons from '../../common/Icons'

export default function ({ placeholder, onSearch, readOnly }) {
  const [text, setText] = useState('')

  function handleSearch() {
    onSearch(text)
  }

  function handleText(e) {
    setText(e.target.value)
  }

  function handleCleanSearch() {
    setText('')
    onSearch('')
  }

  return (
    <Container>
      <input
        type="text"
        className="input"
        placeholder={placeholder}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            handleSearch()
          }
        }}
        onKeyUp={(e) => {
          console.log(text)
          if (e.key === 'Backspace' && text === '') {
            handleCleanSearch()
          }
        }}
        onChange={handleText}
        value={text}
        readOnly={readOnly}
      />
      {text.trim().length > 0 && (
        <button
          type="button"
          className="searchbar__action"
          onClick={handleCleanSearch}
        >
          <Icons icon="dismissFilled" />
        </button>
      )}
      <button
        type="button"
        className="searchbar__action"
        onClick={handleSearch}
      >
        <Icons icon="search" />
      </button>
    </Container>
  )
}
