import styled from 'styled-components'

export default styled.div`
    border-radius: 0.8rem;
    background-color: white;
    font-size: 1.4rem;
    padding: 1rem;
    display: flex;
    justify-content: space-between;

    .value {
        width:100%;
        text-align: center;
        background: #f1f1f1;
        border-radius: 12px;
        color: #012975;
    }
    .value h3 {
        padding: 1.2rem;
    }

`
