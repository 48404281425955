import React, { useEffect, useState, useCallback } from 'react'

import Container from './styles'
import { Icons } from '@valid/components'

const deafultState = { state: {error: 'Link inválido'} }

export default function ({ location }) {
  const [redirectTime, setRedirectTime] = useState(5)
  const { state } = location.state? location : deafultState;

  const handleRedirectTime = useCallback(() => {
    if (redirectTime === 0) {
      if (state.redirect) window.location.href = state.redirect
    } else {
      setTimeout(() => {
        setRedirectTime(redirectTime - 1)
      }, 1000)
    }
  }, [redirectTime, state.redirect])

  if(state.redirect) {
    useEffect(() => {
      handleRedirectTime()
    }, [handleRedirectTime])
  }

  return (
    <Container>
      <div className="icon">
        <Icons icon="errorHollow" />
      </div>
      <h1>{state && state.error}</h1>

      {state.redirect && <>
      <p className="text">Você será redirecionado em</p>
      <p className="counter">{redirectTime}</p>
      </>}
    </Container>
  )
}
