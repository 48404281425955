import React, { useState, useEffect } from 'react'
import { Formik, Field, Form } from 'formik'
import * as Yup from 'yup'
import { useSelector, useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import {CircularProgress} from '@material-ui/core'

import Container from './styles'
import {
  validationMessages,
  cpfValidator,
  phoneValidator,
  maskCpfCnpj,
  phoneFormat,
} from '@valid/config'
import {
  Button,
  ButtonsContainer,
  TextInput,
  OnboardTitle,
} from '@valid/components'
import { verifyTokenRole } from 'config/tokenRouter'
import { post } from 'service/api'
import { setToken } from 'store/reducers/token'

const showEmail = {
  is: true,
  then: Yup
    .string()
    .transform((value) => {
      return value ? value.trim() : value
    })
    .email(validationMessages.invalidEmail)
    .required(validationMessages.required),
}

const showPhone = {
  is: true,
  then: Yup.string()
    .required(validationMessages.required)
    .transform((value) => {
      return value ? value.trim() : value
    })
    .test('valid', validationMessages.invalidPhone, (val) => {
      if (val) return phoneValidator(val)
      return false
    }),
}

const validationSchema = Yup.object({
  cpf: Yup.string()
    .required(validationMessages.required)
    .test('valid', validationMessages.invalidCpf, (val) => {
      if (val) return cpfValidator(val)
      return false
    }),
  email: Yup.string().when('showEmail', showEmail),
  phone: Yup.string().when('showPhone', showPhone),
})

const initialValues = {
  cpf: '',
  email: '',
  showEmail: true,
  phone: '',
  showPhone: true,
}

export default function ({ history, location }) {
  const { token } = useSelector((state) => state.token)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const dispatch = useDispatch()
  const [initialData, setInitialData] = useState(initialValues)

  const { state } = location

  const handleBack = () => {
    setLoading(true)
    history.replace({
      pathname: `/dashboard`,
    })
    setLoading(false)
  }

  useEffect(() => {
    const form = {
      phone: true,
      email: true,
    }

    setInitialData({
      showEmail: form.email,
      showPhone: form.phone,
      cpf: initialValues.cpf,
      email: initialValues.email,
      phone: initialValues.phone,
    })
  }, [state])

  async function handleSubmit(values) {
    setLoading(true)
    const data = {
      email: values.email,
      cpf: values.cpf.replace(/[^\d]+/g, ''),
      phone: values.phone.replace(/[^\d]+/g, ''),
    }
    const res = await post({
      url: `/request`,
      token,
      data
    })

    if (res.status === 200) {
      toast.success("Confirmação da Solicitação enviada!")
    } else if (res.status === 401) {
      toast.error('CPF Bloqueado, contacte o administrador')
    } else if (res.data.error === 'UNASSIGNED_CPF') {
      toast.error('CPF não atribuído a pessoa física')
    }

    values.email = ''
    values.phone = ''
    values.cpf = ''

    setError(res.message)
    setLoading(false)
  }

  return (
    <Container
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.4 }}
    >
      <OnboardTitle>Preencha os dados abaixo</OnboardTitle>

      <Formik
        initialValues={initialData}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize={true}
      >
        {(props) => {
          const { values } = props

          return (
            <Form
              className="form"
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleSubmit(props.values)
                }
              }}
            >
              <section>
                <Field
                  name="cpf"
                  label="CPF"
                  mask="cpf"
                  component={TextInput}
                />

                {values.showEmail && (
                  <Field
                    name="email"
                    label="Email"
                    component={TextInput}
                    autoCorrect="off"
                    autoCapitalize="none"
                  />
                )}

                {values.showPhone && (
                  <Field
                    name="phone"
                    label="Celular"
                    mask="phone"
                    component={TextInput}
                  />
                )}
              </section>

              {error && <p className="error">{error}</p>}

              <ButtonsContainer>
                <Button
                  onClick={props.handleSubmit}
                  variant="primary"
                  fullWidth
                >
                  Iniciar
                </Button>
                
                <Button
                  onClick={handleBack}
                  variant="primary"
                  fullWidth
                >
                  Voltar
                </Button>
              </ButtonsContainer>
            </Form>
          )
        }}
      </Formik>

      {loading && (
        <div className="loading">
          <div className="loading__container">
            <CircularProgress />
          </div>
        </div>
        )}
    </Container>
  )
}
