import React, { useEffect, useState } from 'react'
import { Route } from 'react-router-dom'
import { motion } from 'framer-motion'
import { detect } from 'detect-browser'
import { useSelector, useDispatch } from 'react-redux'

import Container from './styles'
import { Icons, Button, ButtonsContainer } from '@valid/components'
import { setViewed } from 'store/reducers/desktop'

const logo = 'https://static-images-inovamind.s3.us-east-1.amazonaws.com/sa/logo.png'
const browser = detect()

export default ({ component: Component, ...rest }) => {
  const { viewed } = useSelector((state) => state.desktop)
  const dispatch = useDispatch()

  const [iosNotSupported, setIosNotSupported] = useState(false)
  const [isDesktop, setIsDesktop] = useState(true)
  const [desktopAgree, setDesktopAgree] = useState(true)

  useEffect(() => {
  }, [])

  function handleDesktopAgree() {
    dispatch(setViewed())
    setDesktopAgree(true)
  }

  return (
    <Route
      {...rest}
      render={(matchProps) => (
        <Container>
          <div className={isDesktop ? 'mobile_container' : 'full_width'}>
            <motion.header
              className="header"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.4 }}
            >
              <img src={logo} alt="" className="header__logo" />
            </motion.header>
            <main className="main">
              {!iosNotSupported && (
                <React.Fragment>
                  {isDesktop ? (
                    <React.Fragment>
                      {!desktopAgree ? (
                        <div className="desktop_warning">
                          <h3>Atenção!</h3>
                          <Icons icon="infoHollow" />
                          <p>
                            Para realizar todo o cadastro, será necessário preencher todos os campos!
                          </p>
                          <ButtonsContainer>
                            <Button
                              variant="primary"
                              onClick={handleDesktopAgree}
                            >
                              Ok, entendi
                            </Button>
                          </ButtonsContainer>
                        </div>
                      ) : (
                          <Component {...matchProps} />
                        )}
                    </React.Fragment>
                  ) : (
                      <Component {...matchProps} />
                    )}
                </React.Fragment>
              )}

              {iosNotSupported && (
                <motion.p
                  className="not_supported"
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.4 }}
                >
                  <Icons icon="infoHollow" />
                  Este navegador não é suportado na plataforma iOS!
                  <br />
                  <small>Tente novamente utilizando o navegador Safari.</small>
                </motion.p>
              )}
            </main>
          </div>
        </Container>
      )}
    />
  )
}
