import styled from 'styled-components'
import { Popover } from '@material-ui/core'

export default styled(Popover)`
    .MuiPopover-paper {
        background-color: #eeeeee;
        font-size: 1.4rem;
        display: flex;
        flex-direction: column;
        border-radius: 1.6rem;
        backdrop-filter: blur(5px);

        button {
            background-color: transparent;
            border: 0;
            outline: none;
            color: #1d2d72;
            padding: 1rem 1.5rem;
            cursor: pointer;
            text-align: left;
            font-size: 1.4rem;
            min-width: 20rem;
            max-width: 26rem;

            &:not(:last-child) {
                border-bottom: 1px solid rgba(255, 255, 255, 0.4);
            }

            &:hover {
                background-color: rgba(255, 255, 255, 0.1);
            }
        }
    }

    .MuiPaper-elevation8 {
        box-shadow: none;
    }
`
