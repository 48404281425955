import React, { useEffect, useState } from 'react'
import { motion } from 'framer-motion'
import { useSelector, useDispatch } from 'react-redux'
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle
} from '@material-ui/core'
import { toast } from 'react-toastify'
import Container from './styles'
import {
  Table,
  TableContainer,
  Pagination,
  Icons,
  Title,
  Button,
  SearchBar,
} from '@valid/components'
import { get, apiDelete } from 'service/api'
import { maskCpfCnpj } from '@valid/config'

export default function () {
  const { token } = useSelector((state) => state.token)
  const dispatch = useDispatch()
  const [page, setPage] = useState(1)
  const [pages, setPages] = useState(1)
  const [search, setSearch] = useState('')
  const [list, setList] = useState([])
  const [clickedItem, setClickedItem] = useState()
  const [loading, setLoading] = useState(true)
  const [open, setOpen] = React.useState(false)

  const handleUnblockClick = (item) => {
    setClickedItem(item)
    setOpen(true)
  }

  const handleClose = () => {
    setClickedItem(null)
    setOpen(false)
  }

  const handleUserPage = async (page, search) => {
    setLoading(true)
    setList([])
    const res = await get({
      url: `/dashboard/blocked/${page}?search=${search}`,
      token,
    })

    if (res.status === 200) {
      const { total_pages, items } = res.data
      setList(items)
      setPages(total_pages)
    }

    setLoading(false)
  }

  useEffect(() => {
    handleUserPage(page, search)
  }, [page, search])

  function handlePageChange(_, number) {
    setPage(number)
  }

  async function handleSearch(value) {
    setSearch(value)
    handleUserPage(page, search)
  }

  const unblockClient = async (cpf) => {
    let res = null
    if (cpf) {
      res = await apiDelete({
        url: `/dashboard/blocked/${cpf}/reset`,
        token,
      })
    }
    if (res) {
      if (res.status === 200) {
        toast.success('Usuário desbloqueado com sucesso')
        handleClose()
        handleUserPage(page, search)
      }
      else {
        toast.error(res.data.message)
      }
    }
  }

  return (
    <Container>
      <motion.div
        initial={{ opacity: 0, x: 30 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.4 }}
      >
        <Title>Clientes Bloqueados</Title>

        <div class="search">
          <SearchBar 
              placeholder="Pesquisar"
              onSearch={handleSearch}
          />
        </div>
      </motion.div>

      <motion.div
        initial={{ opacity: 0, y: 30 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.4 }}
      >
        <TableContainer>
          <Table>
            <thead>
              <tr>
                <th>ID</th>
                <th>Name</th>
                <th>CPF</th>
                <th>Quiz</th>
                <th>Desbloquear</th>
              </tr>
            </thead>
            <tbody>
              {list.length === 0 &&
                <tr key={"-1"}>
                  <td colSpan={8}>Nenhum registro encontrado.</td>
                </tr>
              }
              {list.map((item) => (
                <tr key={item._id}>
                  <td>
                    {item._id}
                  </td>
                  <td>
                    {item.name}
                  </td>
                  <td>
                    {maskCpfCnpj(item.cpf)}
                  </td>
                  <td>
                    {item.quiz_attempts}
                  </td>
                  <td>
                    <div>
                      <Button variant="outlined" color="primary" 
                        onClick={() => handleUnblockClick(item)}>
                        <Icons icon="exclamation" />
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </TableContainer>

        <Pagination pages={pages} onChange={handlePageChange} />
      </motion.div>

      {clickedItem && (
        <Dialog open={open} onClose={handleClose} >
            <DialogTitle style={{ color: '#1d2d72' }}>
              Confirma o desbloqueio do cliente:
              <br/><br/>
              CPF: {clickedItem.cpf}<br/>
              {clickedItem.name}
            </DialogTitle>
            <DialogActions style={{ margin: "0 auto" }}>
              <Button onClick={() => unblockClient(clickedItem.cpf)}>
                SIM
              </Button>
              <Button onClick={handleClose}>
                NÃO
                </Button>
            </DialogActions>
        </Dialog>
      )}

      {loading && (
        <div className="loading">
          <div className="loading__container">
            <CircularProgress />
          </div>
        </div>
      )}
    </Container>
  )
}
