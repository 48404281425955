import { useSelector, useDispatch } from 'react-redux'
import React, { useEffect, useState } from 'react'
import Container from './styles'
import { toast } from 'react-toastify'
import {
  Button,
  ButtonsContainer,
  OnboardTitle,
  OnboardSubtitle,
  ContactInput
} from '@valid/components'
import Sms from 'assets/sms.svg'
import Email from 'assets/email.svg'
import Quiz from 'assets/quiz.svg'
import { get } from 'service/api'
import { useHistory, useParams } from "react-router";
const validations = [
  { id: 1, name: 'SMS', selected: false, type: 'sms', image: Sms },
  { id: 2, name: 'EMAIL', selected: false, type: 'email', image: Email },
  { id: 3, name: 'QUIZ', selected: false, type: 'quiz', image: Quiz },
]

export default function () {
  const history = useHistory()
  const [buttonEnabled, setButtonEnabled] = useState(false)
  const { token } = useSelector((state) => state.token)
  const { requestId } = useParams()
  const [selectedVal, setSelectedVal] = useState(null)
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const [handleButtonEvent, setButtonEvent] = useState(null)
  const [currentName, setCurrentName] = useState(null)
  const [request, setRequest] = useState({
    sms: false,
    email: true,
    quiz: false,
  })


  // TODO: Criar endpoint para confirmar CPF bloqueado
  if (!request.sms && !request.email && !request.quiz){
    history.replace({
      pathname: "/fail",
      state: {
        error: 'CPF Bloqueado, contacte o administrador',
        redirect: "/dashboard"
      }
    })
  }

  async function handleSubmit(service) {
    setLoading(true)

    history.push({
      pathname: `/${service}/${requestId}`,
      state: {
        requestId: requestId
      }
    })
  }

  useEffect(() => {
    if (history.location) {
      const { error } = history.location.state || {}
      toast.error(error)
    }

    const retrieveRequest = async () => {
      const res = await get({
        url: `/request/${requestId}`,
        token
      })

      const { name } = res.data

      console.log(res)

      setCurrentName(name)
      setLoading(false)

      if (res.status === 200) {
        setRequest(res.data)
      }
      else {
        const { errorCode, errorMessage } = { errorCode: res.data.error, errorMessage: res.data.message }
        if (errorCode === 'REQUEST_ALREADY_FINISHED') {
          history.replace({
            pathname: "/fail",
            state: {
              error: errorMessage,
              redirect: "/dashboard"
            }
          })
        }
        else {
          toast.error(errorMessage)
          history.push('/home')
        }

      }
    }
    retrieveRequest()
  }, [])

  function handleSelectVal(val) {
    setSelectedVal(val)
    setButtonEnabled(true)
    setButtonEvent(val.id)
  }
  function homeReturn() {
    history.replace(`/home`)
  }

  return (
    <Container
      initial={{ opacity: 0, x: 20 }}
      animate={{ opacity: 1, x: 0 }}
      transition={{ duration: 0.4 }}
    >
      <OnboardTitle>Validação</OnboardTitle>
      <OnboardSubtitle>Escolha o tipo de Validação</OnboardSubtitle>
      <ContactInput
        value={currentName}
        loading={loading}
        label="email"
      />
      <ul className="validations">
        {validations.filter(v => request[v.type]).map((val) => (
          <li key={val.id}>
            <button
              type="button"
              className={
                selectedVal && selectedVal.id === val.id ? 'selected' : ''
              }
              onClick={() => handleSelectVal(val)}
            >
              {val.name}
              <img src={val.image} alt="" />
            </button>
          </li>
        ))}
      </ul>

      <ButtonsContainer>
        <Button
          loading={loading}
          onClick={
            handleButtonEvent === 1 ? () => handleSubmit('sms') :
              handleButtonEvent === 2 ? () => handleSubmit('email') :
                handleButtonEvent === 3 ? () => handleSubmit('quiz') :
                  null
          }
          variant={buttonEnabled ? 'primary' : ''}
        >
          Continuar
        </Button>
        <Button
          loading={loading}
          onClick={homeReturn}
          variant='primary'
        >
          Voltar
        </Button>
      </ButtonsContainer>

    </Container>
  )
}
