import React, { useEffect, useState } from 'react'
import { motion } from 'framer-motion'
import { useSelector, useDispatch } from 'react-redux'
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle
} from '@material-ui/core'
import { toast } from 'react-toastify'
import Container from './styles'
import {
  Table,
  TableContainer,
  Pagination,
  Icons,
  Title,
  Button,
  SearchBar,
} from '@valid/components'
import { get, apiDelete } from 'service/api'
import { maskCpfCnpj } from '@valid/config'

export default function () {
  const { token } = useSelector((state) => state.token)
  const dispatch = useDispatch()
  const [page, setPage] = useState(1)
  const [pages, setPages] = useState(1)
  const [search, setSearch] = useState('')
  const [list, setList] = useState([])
  const [loading, setLoading] = useState(true)
  const [open, setOpen] = React.useState(false)

  const handleUserPage = async (page, search) => {
    setLoading(true)
    setList([])
    const res = await get({
      url: `/dashboard/unlocked/${page}?search=${search}`,
      token,
    })

    if (res.status === 200) {
      const { total_pages, items } = res.data
      setList(items)
      setPages(total_pages)
    }

    setLoading(false)
  }

  useEffect(() => {
    handleUserPage(page, search)
  }, [page, search])

  function handlePageChange(_, number) {
    setPage(number)
  }

  async function handleSearch(value) {
    setSearch(value)
    handleUserPage(page, search)
  }

  return (
    <Container>
      <motion.div
        initial={{ opacity: 0, x: 30 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.4 }}
      >
        <Title>Histórico de Desbloqueio</Title>

        <div class="search">
          <SearchBar 
              placeholder="Pesquisar"
              onSearch={handleSearch}
          />
        </div>
      </motion.div>

      <motion.div
        initial={{ opacity: 0, y: 30 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.4 }}
      >
        <TableContainer>
          <Table>
            <thead>
              <tr>
                <th>ID</th>
                <th>Nome do cliente</th>
                <th>CPF</th>
                <th>Data de Bloqueio</th>
                <th>Hora de Bloqueio</th>
                <th>Desbloqueado Por</th>
                <th>ID Usuário</th>
                <th>Data de Desbloqueio</th>
                <th>Hora de Desbloqueio</th>
              </tr>
            </thead>
            <tbody>
              {list.length === 0 &&
                <tr key={"-1"}>
                  <td colSpan={8}>Nenhum registro encontrado.</td>
                </tr>
              }
              {list.map((item) => (
                <tr key={item._id}>
                  <td>
                    {item._id}
                  </td>
                  <td>
                    {item.client_name}
                  </td>
                  <td>
                    {maskCpfCnpj(item.client_cpf)}
                  </td>
                  <td>
                    {item.blocked_date}
                  </td>
                  <td>
                    {item.blocked_time}
                  </td>
                  <td>
                    {item.user_name}
                  </td>
                  <td>
                    {item.user_id}
                  </td>
                  <td>
                    {item.unlock_date}
                  </td>
                  <td>
                    {item.unlock_time}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </TableContainer>

        <Pagination pages={pages} onChange={handlePageChange} />
      </motion.div>
      {loading && (
        <div className="loading">
          <div className="loading__container">
            <CircularProgress />
          </div>
        </div>
      )}
    </Container>
  )
}
