import React, { useEffect } from 'react'
import { FailContainer } from './styles'

export default function ({ history }) {
  return (
    <FailContainer
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.4 }}
    >
      <h1>Agradecemos a sua resposta</h1>
    </FailContainer>
  )
}
