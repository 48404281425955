import styled from 'styled-components'

export default styled.button`
  background-color: ${(props) => props.theme.palette.gray[300]};
  border: 0;
  border-radius: 0.4rem;
  outline: none;
  cursor: pointer;
  color: #1d2d72;
  font-size: 1.4rem;
  text-transform: uppercase;
  font-weight: 600;
  padding: 0 2rem;
  transition: all 0.3s ease;
  height: 4.2rem;

  &.full_width {
    width: 30rem;
  }

  &.primary {
    background-color: ${(props) => props.theme.palette.primary};
    color: ${(props) => props.theme.palette.contrast};
  }

  &.transparent {
    background-color: ${(props) => props.theme.palette.contrast};
    color: ${(props) => props.theme.palette.primary};
  }

  &.loading {
    cursor: default !important;
    background-color: ${(props) => props.theme.palette.gray[200]};
    border-radius: 3rem;
    padding: 0;
    padding-top: 0.5rem;
    width: 4.2rem;
  }

  .loader {
    width: 3rem !important;
    height: 3rem !important;

    svg {
      width: 3rem;
      height: 3rem;
    }
  }
`

// export default styled.div`
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   width: 100%;

//   .button {
//     background-color: ${(props) => props.theme.palette.gray[300]};
//     color: ${(props) => props.theme.palette.gray[800]};
//     border: 0;
//     border-radius: 0.4rem;
//     width: 100%;
//     max-width: 30rem;
//     font-size: 1.2rem;
//     font-weight: 600;
//     text-transform: uppercase;
//     height: 4rem;
//     outline: none;
//     cursor: pointer;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     padding: 1rem;
//     transition: all 0.3s ease;

//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     width: 100%;

//     &:focus {
//       outline: none;
//     }

//     &__loader {
//       width: 3rem !important;
//       height: 3rem !important;

//       svg {
//         width: 3rem;
//         height: 3rem;
//       }
//     }

//     &--primary {
//       background-color: ${(props) => props.theme.palette.primary};
//       color: ${(props) => props.theme.palette.contrast};
//     }

//     &__loading {
//       cursor: default !important;
//       background-color: ${(props) => props.theme.palette.gray[100]};
//       border-radius: 3rem;
//       width: 4rem;
//       padding: 0.5rem;
//     }
//   }
// `

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 3rem;

  & > button:not(:first-child) {
    margin-left: 3rem;
  }
`
