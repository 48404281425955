import styled from 'styled-components'
import { Dialog } from '@material-ui/core'

export default styled.div`
  margin-top: 4rem;

  .search {
    width: 100%;
    padding: 10px 60% 10px 0; 
    text-align: left;
  }

  .column-items {
    display: flex;
    flex-flow: column nowrap;

    &:not(:first-child) {
      margin-left: 1rem !important;
    }
  }

  .action-buttons button {
    margin-left: 10px
  }

  .item-column {
    flex: 1;
    width: 100% !important;
    margin: 0 !important;


    &:not(:first-child) {
      margin-top: 0.5rem !important; 
    }
  }

  .right-buttons button {
    margin-left: 10px;
  }
  
  .loading {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.8);

    &__container {
      background-color: white;
      padding: 1.5rem;
      border-radius: 50%;

      svg {
        height: 4rem;
        width: 4rem;
      }
    }
  }
`

export const RowButton = styled.button`
  background-color: ${(props) => props.theme.palette.gray[200]};
  border: 0;
  border-radius: 0.8rem;
  outline: none;
  padding: 1rem;
  cursor: pointer;
  font-size: 1rem;

  display: block;

  &:not(:first-child) {
    margin-left: 1rem;
  }

  &:only-child {
    margin: auto;
  }

  &:hover {
    background-color: ${(props) => props.theme.palette.gray[300]};
  }

  svg {
    margin: 0 !important;
  }

  span {
    display: block;
    margin-top: 0.2rem;
  }
`

export const DocumentModal = styled(Dialog)`
  .MuiDialog-paper {
    max-width: 90%;
    padding: 2rem;
    border-radius: 1.6rem;

    .modal_document {
      display: block;
      max-height: 60rem;
      width: auto;
      height: auto;
      background-color: ${(props) => props.theme.palette.gray[200]};
    }
  }
`

export const ResultModal = styled(Dialog)`
  .MuiDialog-paper {
    max-width: 90%;
    padding: 2rem;
    border-radius: 1.6rem;

    .body {
      display: grid;
      grid-gap: 2rem;
      grid-template-columns: 1fr 1fr;

      .left-section {
        .image {
          display: block;
          max-width: 40rem;
          width: auto;
          height: auto;
          background-color: ${(props) => props.theme.palette.gray[200]};
          border-radius: 0.8rem;
        }
      }

      .right-section {
        .name {
          font-weight: 500;
          font-size: 2.4rem;
          margin-bottom: 2rem;
        }

        .checklist-title {
          font-size: 1.6rem;
          font-weight: 500;
          color: ${(props) => props.theme.palette.gray[400]};
          border-bottom: 1px solid ${(props) => props.theme.palette.gray[500]};
          padding-bottom: 1rem;
        }

        .checklist-item {
          display: flex;
          align-items: center;
          padding: 1rem 0;
          border-bottom: 1px solid ${(props) => props.theme.palette.gray[500]};

          b {
            font-weight: 600;
            margin-right: 2rem;
            width: 11rem;
            display: inline-block;
          }

          span {
            font-size: 1.2rem;
            margin-right: 1rem;
          }
        }
      }
    }
  }
`
