import styled from 'styled-components'
import { motion } from 'framer-motion'

export default styled(motion.div)`
  .loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50vh;

    svg {
      color: ${(props) => props.theme.palette.gray[300]};
    }
  }

  .error {
    color: #e31010;
    margin: 2rem;
    font-size: 1.4rem;
    text-align: center;
    font-weight: 600;
  }

  .quiz_container {
    p {
      font-size: 1.6rem;
      margin: 3rem 0;
    }

    .answers {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: left;

      button {
        flex: 1;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        color: #000;
        text-align: left;
        
        padding: 1.2rem 1rem;
        font-size: 1.2rem;
        background-color: transparent;
        outline: none;
        border: 2px solid transparent;
        border-radius: 10px;
        border-color: #BBB;
        box-shadow: 0 2px 3px 2px rgba(0, 0, 0, 0.1);

        &:not(:first-child) {
          margin-top: 1.5rem;
        }
        
        &:hover{
          border-color: #00AA00;
        }

        svg {
          flex: 1;
          color: #EEEEEE;
        }

        label {
          flex: 9;
        }
      }
    }


    .quiz_timer {
      display: flex;
      justify-content: flex-end;
      font-size: 2rem;
      color: ${(props) => props.theme.palette.gray[400]};
    }
  }

  .sending {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.8);

    &__container {
      background-color: white;
      padding: 1.5rem;
      border-radius: 50%;

      svg {
        height: 4rem;
        width: 4rem;
      }
    }
  }

  .dont-break-out {

    /* These are technically the same, but use both */
    overflow-wrap: break-word;
    word-wrap: break-word;
  
    -ms-word-break: break-all;
    /* This is the dangerous one in WebKit, as it breaks things wherever */
    word-break: break-all;
    /* Instead use this non-standard one: */
    word-break: break-word;
  
    /* Adds a hyphen where the word breaks, if supported (No Blink) */
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
  
  }
`

export const FailContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  h1 {
    font-size: 2.4rem;
    color: ${(props) => props.theme.palette.primary};
    margin-bottom: 2rem;
  }

  h4 {
    font-size: 1.6rem;
    font-weight: 600;
    color: ${(props) => props.theme.palette.gray[600]};
    margin-bottom: 2rem;
  }

  .hint {
    border: 1px solid ${(props) => props.theme.palette.gray[200]};
    border-radius: 0.8rem;
    padding: 1rem;
    margin: 0 2rem;
    margin-bottom: 4rem;

    svg {
      width: 2.4rem;
      height: 2.4rem;
      margin-bottom: 1rem;
      color: #fcba03;
    }

    p {
      font-size: 1.4rem;
    }
  }
`
