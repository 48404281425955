import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import UnsignedLayout from 'ui/layouts/Unsigned'
import SignedInLayout from 'ui/layouts/SignedIn'
import DefaultLayout from 'ui/layouts/Default'
import ErrorLayout from 'ui/layouts/Error'
import ProtectedRoute from 'routes/ProtectedRoute'

import Login from 'ui/views/Login'
import Home from 'ui/views/Home'
import Sms from 'ui/views/Sms'
import Dashboard from 'ui/views/Dashboard'

import HistoryUnlock from 'ui/views/HistoryUnlock'
import Quiz from 'ui/views/Quiz'
import QuizFail from 'ui/views/Quiz/Fail'
import QuizFinished from 'ui/views/Quiz/Finished'
import RequestSuccess from 'ui/views/RequestConfirmation/Success'
import RequestFinished from 'ui/views/RequestConfirmation/Finished'
import RequestFail from 'ui/views/RequestConfirmation/Fail'
import Result from 'ui/views/Result'
import Email from 'ui/views/Email'
import Fail from 'ui/views/Fail'
import ServiceSelection from 'ui/views/ServiceSelection'
import Success from 'ui/views/Success'
import Users from 'ui/views/User'
import Blocked from 'ui/views/Blocked'
import RecoverPassword from 'ui/views/RecoverPassword'
import RecoverPasswordForm from 'ui/views/RecoverPasswordForm'
import RequestConfirmation from 'ui/views/RequestConfirmation'

export default () => {
  return (
    <Switch>
      <UnsignedLayout exact path="/" component={Login}/>
      <UnsignedLayout exact path="/login" component={Login} />
      <UnsignedLayout exact path="/login/recover"  component={RecoverPassword} />
      <UnsignedLayout exact path="/login/recoverForm"  component={RecoverPasswordForm} />

      {/*
        TODO: Tela de confirmação se foi o usuário quem solicitou o resgate
         - A tela deve redirecionar para tela de quiz se ele possui email ou phone nao esta na bigdata
         - A tela deve redirecionar para tela de validacao confirmada ou negada, se email ou phone esta na bigdata
      */}
      <UnsignedLayout exact 
        path="/:service/confirmation/:requestId" 
        parent={DefaultLayout} 
        child={RequestConfirmation}
        redirectlogged={false} />

      <UnsignedLayout exact 
        path="/confirmation/:requestId/finished" 
        parent={DefaultLayout}
        child={RequestFinished}
        redirectlogged={false}
      />

      <UnsignedLayout exact 
        path="/confirmation/:requestId/fail" 
        parent={DefaultLayout}
        child={RequestFail}
        redirectlogged={false}
      />

      <UnsignedLayout exact 
        path="/confirmation/:requestId/success" 
        parent={DefaultLayout}
        child={RequestSuccess}
        redirectlogged={false}
      />

      <ProtectedRoute path="/home" parent={DefaultLayout} child={Home} />
      {/* <ProtectedRoute path="/service/selection/:requestId" parent={DefaultLayout} child={ServiceSelection} /> */}
      {/* <ProtectedRoute path="/email/:requestId" parent={DefaultLayout} child={Email} /> */}
      {/* <ProtectedRoute path="/sms/:requestId" parent={DefaultLayout} child={Sms} /> */}
      <UnsignedLayout exact 
        path="/quiz/:requestId/fail"
        parent={DefaultLayout}
        child={QuizFail}
        redirectlogged={false}
      />
      <UnsignedLayout exact 
        path="/quiz/:requestId/finished"
        parent={DefaultLayout}
        child={QuizFinished}
        redirectlogged={false}
      />
      <UnsignedLayout exact 
        path="/quiz/:requestId"
        parent={DefaultLayout} 
        child={Quiz} 
        redirectlogged={false} />
      {/* <ProtectedRoute path="/quiz/:requestId" parent={DefaultLayout} child={Quiz} /> */}
      <UnsignedLayout exact 
        path="/success" 
        parent={DefaultLayout} 
        child={Success} 
        redirectlogged={false}
      />
      <ProtectedRoute path="/users" level="admin" parent={SignedInLayout} child={Users} />
      <ProtectedRoute path="/dashboard/blocked" level="admin/manager" parent={SignedInLayout} child={Blocked} />
      <ProtectedRoute path="/dashboard/unlock/history" level="admin" parent={SignedInLayout} child={HistoryUnlock} />


      <ProtectedRoute path="/result" parent={DefaultLayout} child={Result} />

      <ErrorLayout path="/fail" component={Fail} />

      <ProtectedRoute
        path="/dashboard"
        parent={SignedInLayout}
        child={Dashboard} />


      <Route default render={() => (
        <Redirect to="/login" />
      )} />
    </Switch>
  )
}
