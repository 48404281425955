import { call, put } from 'redux-saga/effects'
import { push, replace } from 'connected-react-router'

import { QUIZ_SUCCESS, QUIZ_FAILURE, QUIZ_RESET, QUIZ_FINISHED } from 'store/reducers/quiz'
import { TOKEN_REQUEST } from 'store/reducers/token'
import { get, post } from 'service/api'



export function* handleFetchQuestions(action) {
  const { requestId } = action.payload
  const res = yield call(get, {
    url: `/request/${requestId}/quiz`,
    token: false,
  })

  if (res.status === 200) {
    yield put({
      type: QUIZ_SUCCESS,
      payload: res.data.questions,
    })
  } else {
    yield put({
      type: QUIZ_FAILURE,
      payload: res.data.message,
    })
    if (res.data.finished) {
      yield put(replace(`/quiz/${requestId}/finished`))
    }
    else {
      yield put(push(`/service/selection/${requestId}`))
    }
  }
}

export function* handleSubmitAnswers(action) {
  const { requestId, items } = action.payload

  const res = yield post({
    url: `/request/${requestId}/quiz/verify`,
    token: false,
    data: { answers: items },
  })

  if (res.status === 200) {

    if (res.data.questions) {
      yield put({
        type: QUIZ_SUCCESS,
        payload: res.data.questions,
      })
    } else {
      yield put({
        type: QUIZ_FINISHED,
        payload: {
          success: true
        }
      })
    }
  } else {
    yield put({
      type: QUIZ_FAILURE,
      payload: res.data.message
    })
    if (!res.data.finished) {
      yield put(replace(`/quiz/${requestId}/fail`))
    }
    else {
      yield put(replace(`/quiz/${requestId}/finished`))
    }
  }

}

export function* handleRetry(action) {
  const res = yield call(get, {
    url: '/register/quiz/retry',
    token: false,
  })

  if (res.status === 200) {
    yield put({
      type: TOKEN_REQUEST,
      payload: res.data.token,
    })

    yield put({
      type: QUIZ_RESET,
    })

    yield put(push('/quiz'))
  } else {
    yield put({
      type: QUIZ_FAILURE,
      payload: res.data.message,
    })
    yield put({
      type: QUIZ_RESET,
      payload: {},
    })
    if (res.data.finished) {
      yield put({
        type: QUIZ_FINISHED,
        payload: {}
      })
    }

    yield put(push('/quiz/fail'))
  }
}


export function* handleTimeout(action) {
  // const { requestId } = action.payload
  // console.log(action.payload)
  // const res = yield call(get, {
  //   url: `/request/${requestId}/quiz/timeout`,
  //   token: false,
  // })
  // yield put({
  //   type: QUIZ_FAILURE,
  //   payload: res.data.message,
  // })
  // yield put({
  //   type: QUIZ_RESET,
  //   payload: {},
  // })

  // if (res.status === 400) {
  //   if (res.data.finished) {
  //     yield put({
  //       type: QUIZ_FINISHED,
  //       payload: {}
  //     })
  //     yield put(push(`/quiz/${requestId}/finished`))

  //   }
  //   else {
  //     yield put(push(`/quiz/${requestId}/fail`))
  // }

  // }


}
