import React from 'react'
import { motion } from 'framer-motion'

import { useEffect, useState, useCallback } from 'react'

import Container from './styles'
import { Icons } from '@valid/components'
export default function ({ history, location }) {
  const [redirectTime, setRedirectTime] = useState(5)
  const { state } = location

  const handleRedirectTime = useCallback(() => {
    if (redirectTime === 0) {
      window.location.href = state.redirect || '/dashboard'
    } else {
      setTimeout(() => {
        setRedirectTime(redirectTime - 1)
      }, 1000)
    }
  }, [redirectTime, state.redirect])

  useEffect(() => {
    handleRedirectTime()
  }, [handleRedirectTime])

  function handleSubmit() {
    history.replace("/dashboard")
  }
  return (
    <Container>
      <div className="icon">
        <Icons icon="check" />
      </div>
      <h1>{state.message}</h1>

      <p className="text">Você será redirecionado em</p>
      <p className="counter">{redirectTime}</p>
    </Container>
    // <Container>
    //   <div className="full_width">
    //     <motion.header
    //       className="header"
    //       initial={{ opacity: 0 }}
    //       animate={{ opacity: 1 }}
    //       transition={{ duration: 0.4 }}
    //     >
    //     </motion.header>
    //     <main className="main">
    //       <React.Fragment>
    //         <div className="desktop_warning">
    //           <h3>Sucesso!</h3>
    //           <Icons icon="check" />
    //           <p> Token validado! </p>
    //           <ButtonsContainer>
    //             <Button
    //               variant="primary"
    //               onClick={handleSubmit}
    //             >
    //               Retornar a tela inicial
    //             </Button>
    //           </ButtonsContainer>
    //         </div>
    //       </React.Fragment>
    //     </main>
    //   </div>
    // </Container>
  )
}
