import React, {useState} from 'react'
import { Formik, Field, Form } from 'formik'
import * as Yup from 'yup'
import { useSelector, useDispatch } from 'react-redux'
import { motion } from 'framer-motion'
import { post } from 'service/api'
import { Link, useLocation, BrowserRouter as Router } from "react-router-dom";

import Container from './styles'
import { login } from 'store/reducers/auth'
import { validationMessages } from '@valid/config'
import { Button, ButtonsContainer, TextInput } from '@valid/components'
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle
} from '@material-ui/core'
import { toast } from 'react-toastify'

const validationSchema = Yup.object({
  Email: Yup.string().required(validationMessages.required),
})

const containerVariants = {
  initial: { y: 0, opacity: 0 },
  formInitial: { y: -40, opacity: 0 },
  formAnimate: { y: 0, opacity: 1 },
}

const initialValues = {
  newPassword: '', 
  passwordConfirmation: ''
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function ({ location, history }) {
  const token = useSelector((state) => state.token)
  const [passwordData] = useState(initialValues)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const query = useQuery()

  console.log()

  if (token.length > 0) {
    history.replace('/dashboard')
  }

  const { state } = location

  const handleBack = () => {
    history.replace({
      pathname: `/login`,
    })
  }

  async function handleSubmit(values) {
    try {
      
      setLoading(true)

      const token = query.get("token")
      console.log(values)
      console.log(token)

      if (!values.newPassword || !values.passwordConfirmation){
        setError('A senha e confirmação de senha devem ser fornecidas')
      } else if (values.newPassword !== values.passwordConfirmation) {
        setError('As senhas informadas são diferentes')
      } else {

        const res = await post({
          url: `/passwords`,
          token: false,
          data: {
            token: token,
            password: values.newPassword
          }
        })

        setLoading(false)

        if (res.status === 200) {
            history.replace({pathname: '/login'})
            toast.info('Senha alterada com sucesso')
            return 
        }

        if(res.data.error == 'INVALID_SENHA'){
          toast.error('Senha não é válida')
            return
        } 
        
        if(res.data.error == 'INVALID_TOKEN'){
            toast.error('Token utilizado é inválido ou expirou')
            return
        } 

        toast.error('Não foi possível alterar a senha')
      }
    } catch (error) {
      setLoading(false)
      toast.error('Não foi possível alterar a senha')
    }
  }

  return (
    <motion.div
      variants={containerVariants}
      initial={state && state.fromForgot ? 'formInitial' : 'initial'}
      animate="formAnimate"
      transition={{ duration: 0.4 }}
    >
      <Container>
        <Formik
          initialValues={passwordData}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {(props) => {
            return (
              <Form
                className="form"
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleSubmit(props.values)
                  }
                }}
              >
                <Field
                  name="newPassword"
                  type="password"
                  label="Insira a nova senha"
                  placeholder="Senha"
                  component={TextInput}
                />
                <Field
                  name="passwordConfirmation"
                  type="password"
                  label="Confirme sua senha"
                  placeholder="Senha"
                  component={TextInput}
                />

                {error && <p className="error">{error}</p>}

                <ButtonsContainer>
                  <Button
                    onClick={() => handleSubmit(props.values)}
                    variant="primary"
                    fullWidth
                  >
                    Alterar
                  </Button>
                  <Button
                    onClick={handleBack}
                    variant="primary"
                    fullWidth
                  >
                    Ir para tela de login
                  </Button>
                </ButtonsContainer>
               </Form>
            )
          }}
        </Formik>
      </Container>
    </motion.div>
  )
}
