import React, { useEffect } from 'react'
import { FailContainer } from './styles'

export default function ({ history }) {
  return (
    <FailContainer
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.4 }}
    >
      <h1>Solicitação de Resgate Inválida</h1>
      <h4>
        Não foi possível obter informações <br/> 
        desta solicitação
      </h4>
      <h4>
        Entre em contato através de um de nossos atendimentos
        para mais informações.
      </h4>
    </FailContainer>
  )
}
