import React, { useEffect, useState, useCallback } from 'react'
import { motion } from 'framer-motion'
import { useSelector, useDispatch } from 'react-redux'
import { CircularProgress, Icon } from '@material-ui/core'
import { Formik, Field, Form } from 'formik'
import { toast } from 'react-toastify'

import Container, { RowButton, DocumentModal, ResultModal } from './styles'
import {
  Table,
  TableContainer,
  TextInput,
  Pagination,
  Icons,
  Title,
  SearchBar,
  Button
} from '@valid/components'
import { fetch } from 'store/reducers/dashboard'
import { maskCpfCnpj } from '@valid/config'
import { get } from 'service/api'
import { avatar } from 'assets/default_avatar.jpg'

export default function () {
  const { token } = useSelector((state) => state.token)
  const { level } = useSelector((state) => state.user)
  const dashboard = useSelector((state) => state.dashboard)
  const dispatch = useDispatch()
  const [page, setPage] = useState(1)
  const [name, setName] = useState('')
  const [cpf, setCpf] = useState('')
  const [pageLoading, setPageLoading] = useState(false)

  const { list, pages, loading } = dashboard

  const handleFetchPage = useCallback(
    async (number, searchName, searchCpf) => {

      if (token) {
        dispatch(fetch({ 
          token, 
          page: number, 
          name: searchName, 
          cpf: searchCpf 
        }))
      }
    },
    [dispatch, token],
  )

  useEffect(() => {
    handleFetchPage(page, name, cpf)
  }, [handleFetchPage, page, name, cpf])

   async function handleSearch(value) {
      setName(value)
      setCpf(value)
      handleFetchPage(page, name, cpf)
  }

  function handlePageChange(_, number) {
    setPage(number)
  }

  function callPrint() {
    window.print();
  }

  function returnColorComponent(color) {
    if (color === true) return <Icons icon="check" />
    else if (color === null) return <Icons icon="info" />
    else if (color === false) return <Icons icon="error" />
    else return null
  }

  function returnResultComponent(result, result_color) {
    if (result === true) return <Icons icon="check" color={result_color}/>
    else if (result === null) return <Icons icon="info" color={result_color}/>
    else if (result === false) return <Icons icon="error" color={result_color}/>
    else return null
  }

  async function handleExportFile() {
    setPageLoading(true)
    const res = await get({
        url: `/dashboard/export?name=${name}&cpf=${cpf}`,
        token,
        responseType: 'blob'
    })

    try {
        if (res && res.data) {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', "backoffice.csv")
            document.body.appendChild(link)
            link.click()
            setPageLoading(false)
        }
        else {
          setPageLoading(false)
            toast.error("Não foi possível exportar o relatório")
        }

    } catch (error) {
        toast.error("Não foi possível exportar o relatório")
        setPageLoading(false)
    }   
  }

  return (
    <Container>
      <motion.div
        initial={{ opacity: 0, x: 30 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.4 }}
      >
        <Title>Dashboard</Title>
      </motion.div>

    <div class="search">
      <SearchBar 
          placeholder="Pesquisar"
          onSearch={(e) => handleSearch(e)}
      />
    </div>

      <motion.div
        initial={{ opacity: 0, y: 30 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.4 }}
      >
        <TableContainer>
          <Table>
            <thead>
              <tr>
                <th>ID</th>
                <th>Nome</th>
                <th>Documento</th>
                <th>Data cadastro</th>
                <th>SMS</th>
                <th>E-Mail</th>
                <th>Resultado</th>
              </tr>
            </thead>
            <tbody>
              {list.length === 0 &&
                <tr key={"-1"}>
                  <td colSpan={8}>Nenhum registro encontrado.</td>
                </tr>
              }
              {list.map((item) => (
                <tr key={item._id}>
                  <td>{item._id}</td>
                  <td>{item.name}</td>
                  <td>
                    {maskCpfCnpj(item.document.document)}
                  </td>
                  <td>{item.date_request}</td>
                  <td>
                    <div className="row center">
                      <div className="column-items">
                          <RowButton
                            className='item-column'
                          >
                            {returnColorComponent(item.sms.sms_sent)}
                            <span>Enviado</span>
                          </RowButton>
                          <RowButton
                            className='item-column'
                          >
                            {returnColorComponent(item.sms.sms_valid)}
                            <span>Visualizado</span>
                          </RowButton>
                      </div>
                      <div className="column-items">
                              <RowButton
                                className='item-column'
                              >
                                {returnColorComponent(item.sms.quiz_required)}
                                <span>Quiz Obrigatório</span>
                              </RowButton>
                        <div className="row center item-column">
                            <RowButton
                            >
                              {returnColorComponent(item.sms.quiz_started)}
                              <span>Quiz Iniciado</span>
                            </RowButton>
                            <RowButton
                            >
                              <strong>{item.sms.quiz_tries}</strong>
                              <span>Tentativa(s)</span>
                            </RowButton>
                            <RowButton
                            >
                              {returnColorComponent(item.sms.quiz_valid)}
                              <span>Quiz Aprovado</span>
                            </RowButton>
                        </div>
                      </div>
                    </div>
                  </td>
                    <td>
                      <div className="row center">
                        <div className="column-items">
                          
                            <RowButton
                              className='item-column'
                            >
                              {returnColorComponent(item.email.email_sent)}
                              <span>Enviado</span>
                            </RowButton>

                            <RowButton
                              className='item-column'
                            >
                              {returnColorComponent(item.email.email_valid)}
                              <span>Visualizado</span>
                            </RowButton>
                        </div>
                      <div className="column-items">
                            <RowButton
                              className='item-column'
                            >
                              {returnColorComponent(item.email.quiz_required)}
                              <span>Quiz Obrigatório</span>
                            </RowButton>

                          <div className="row center item-column">
                            <RowButton
                            >
                              {returnColorComponent(item.email.quiz_started)}
                              <span>Quiz Iniciado</span>
                            </RowButton>
                            <RowButton
                            >
                              <strong>{item.email.quiz_tries}</strong>
                              <span>Tentativa(s)</span>
                            </RowButton>
                            <RowButton
                            >
                              {returnColorComponent(item.email.quiz_valid)}
                              <span>Quiz Aprovado</span>
                            </RowButton>
                          </div>
                        </div>
                      </div>
                    </td>
                  <td className="centery">
                    <div className="column-items">
                    <span>
                      {item.confirmation}
                    </span>
                    <RowButton
                      className='item-column'
                    >
                      {returnResultComponent(item.result ,item.result_color)}
                    </RowButton>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </TableContainer>

        <Pagination pages={pages} onChange={handlePageChange} />
      </motion.div>

      <div className="action-buttons">
        <Button
          variant="primary"
          onClick={callPrint}>
          Imprimir
        </Button>
        <Button
          variant="primary"
          onClick={handleExportFile}>
          Exportar com filtros
        </Button>        
      </div>
      {(loading || pageLoading) && (
        <div className="loading">
          <div className="loading__container">
            <CircularProgress />
          </div>
        </div>
      )}
    </Container>
  )
}
