import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router'
import { CircularProgress } from '@material-ui/core'
import { motion } from 'framer-motion'
import moment from 'moment'

import Container from './styles'
import { fetchQuestions, submitAnswers, resetQuiz } from 'store/reducers/quiz'
import {
  OnboardTitle,
  Icons,
  AnimatedCheck,
  CountdownTimer,
} from '@valid/components'
import { get } from 'service/api'

export default function ({ history }) {
  const { requestId } = useParams()

  const quiz = useSelector((state) => state.quiz)

  const dispatch = useDispatch()
  const [questionIndex, setQuestionIndex] = useState(0)
  const [currentQuestion, setCurrentQuestion] = useState(null)
  const [showQuestion, setShowQuestion] = useState(false)
  const [answers, setAnswers] = useState([])
  const { questions, loading, error, finished, success, sending} = quiz

  useEffect(() => {
    if (questions.length === 0 && !sending) 
      dispatch(fetchQuestions({ requestId }))
  }, [questions, sending])

  useEffect(() => {
    if (questionIndex === 0) {
      setAnswers([])
    }

    if (questions.length > 0) {
      setCurrentQuestion(questions[questionIndex])
      setShowQuestion(true)
    }
  }, [questions, questionIndex])

  useEffect(() => {
    if (finished) {
      dispatch(resetQuiz())
      if (success) {
        history.replace({
          pathname: '/success',
          state: {
            message: "Quiz validado com sucesso!",
            redirect: `/confirmation/${requestId}/success`
          }
        })
      }

    }
  }, [finished])

  async function handleSubmit(items) {
    dispatch(submitAnswers({ requestId, items }))
  }

  function handleAnswer(answer) {
    setAnswers([...answers, answer])
    const totalQuestions = questions.length - 1
    
    if (answers.length < totalQuestions) {
      setShowQuestion(false)
      setQuestionIndex(questionIndex + 1)
    } else if (answers.length == totalQuestions) {
      setQuestionIndex(0)
      handleSubmit([...answers, answer])
    } else if (answers.length > totalQuestions){
      handleSubmit([answer])
    }
  }

  async function handleTimeout() {
    const res = await get({
      url: `/request/${requestId}/quiz/timeout`,
      token: false
    })
    if (res.status === 200) {

      history.replace(`/quiz/${requestId}/fail`)
    }
    else {
      if (res.data.finished) {
        history.replace(`/quiz/${requestId}/finished`)
      }
      else {
        history.replace(`/quiz/${requestId}/fail`)
      }
    }
  }

  async function handleTimerEnded() {
    if (!sending) {
      handleTimeout()
    }
    return null
  }

  return (
    <Container
      initial={{ opacity: 0, x: 20 }}
      animate={{ opacity: 1, x: 0 }}
      transition={{ duration: 0.4 }}
    >
      <OnboardTitle>Quiz</OnboardTitle>

      {loading && (
        <div className="loading">
          <CircularProgress />
        </div>
      )}

      {!loading && error && <div className="error">{error}</div>}

      {!loading && questions.length > 0 && (
        <React.Fragment>
          {showQuestion && (
            <motion.div
              initial={{ opacity: 0, x: 20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.4 }}
            >
              <div className="quiz_container">
                <div className="quiz_timer">
                  <CountdownTimer
                    time={moment().add(30, 's')}
                    callback={handleTimerEnded}
                  />
                </div>

                <p className="dont-break-out">{currentQuestion.title}</p>
                <div className="answers">
                  {currentQuestion.answers.map((item, index) => (
                      <button
                        key={index}
                        type="button"
                        onClick={() => handleAnswer(item)}
                      >
                          <label>{item}</label>
                      </button>
                  ))}
                </div>
              </div>
            </motion.div>
          )}
        </React.Fragment>
      )}

      {sending && (
        <div className="sending">
          <div className="sending__container">
            <CircularProgress />
          </div>
        </div>
      )}
    </Container>
  )
}
