import styled from 'styled-components'

import background from 'assets/background.png'

export default styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background: url(${background}) no-repeat top right;

  .header {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem;

    &__logo {
      height: 4rem;
      margin-bottom: 2rem;
    }
  }

  .mobile_container {
    margin-top: 4rem;
    width: 45rem;
    height: auto;
    background-color: white;
    box-shadow: 0 3px 5px 2px rgba(0, 0, 0, 0.2);
    border-radius: 0.8rem;
    padding: 2rem;
    padding-top: 0;
    position: relative;
  }
`
