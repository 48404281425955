import React, { useState, useSelector } from "react";
import "./styles.css";
import { Formik, Field, Form } from 'formik'
import * as Yup from 'yup'
import { login } from 'store/reducers/auth'
import { validationMessages } from '@valid/config'
import { Button, ButtonsContainer, TextInput, Select } from '@valid/components'
const UserModal = props => {

  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)
  const { modalRef, isModalOpen, onModalClose, user, handleSubmit, edit, } = props;
  const validationSchema = Yup.object().shape({
    username: Yup.string().min(5).required(validationMessages.required),
    password: Yup.string()
      .when(
        'passwordEnable', { is: true, then: Yup.string().min(5, validationMessages.minPasswordLength).max(200, validationMessages.maxPasswordLength).required(validationMessages.required), }
      ),
    email: Yup.string().email().required(validationMessages.required),
    name: Yup.string().required(validationMessages.required),
    passwordConfirmation: Yup.string().when('passwordEnable', { is: true, then: Yup.string().oneOf([Yup.ref('password')], 'As senhas devem ser iguais').required(validationMessages.required) })

  })

  const handleEnablePassword = (props) => {
    props.setFieldValue('passwordEnable', !props.values.passwordEnable)
    let elem = document.getElementsByClassName('modal')[0]
    elem.style.top = '2rem'
  }


  console.log(user)
  const initialValues = user ? {
    _id: user._id,
    name: user.name,
    username: user.username,
    email: user.email,
    password: '',
    passwordConfirmation: '',
    passwordEnable: false,
    level: user.level
  } : {
      name: '',
      username: '',
      email: '',
      password: '',
      passwordConfirmation: '',
      passwordEnable: true,
      level: 'user'
    }

  const containerVariants = {
    initial: { y: 0, opacity: 0 },
    formInitial: { y: -40, opacity: 0 },
    formAnimate: { y: 0, opacity: 1 },
  }

  return (
    <>
      <div ref={modalRef} className={`${isModalOpen ? "show" : ""} modal-overlay`} onClick={() => onModalClose ? onModalClose() : {}} />
      <div ref={modalRef} className={`${isModalOpen ? "show" : ""} modal`}>
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values) => handleSubmit(values, edit)}
        >
          {(props) => {
            return (
              <Form
                className="form"
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleSubmit(props.values, edit)
                  }
                }}
              >
                <Field
                  name="name"
                  label="Nome Completo"
                  component={TextInput}
                />
                <Field
                  name="username"
                  label="Username"
                  component={TextInput}
                />
                <Field
                  name="email"
                  label="E-mail"
                  component={TextInput}
                />
                <div>
                  <Select
                    label="Nível"
                    field={{ name: "level" }}
                    form={props}
                    options={
                      [
                        { value: 'admin', label: 'Administrador' },
                        { value: 'manager', label: 'Gestor de Operação' },
                        { value: 'user', label: 'Analista de Operação' }
                      ]
                    }
                    labeltype=""
                  ></Select>
                </div>

                {
                  edit && <div style={{ margin: "1.3rem 0" }}><Button
                    onClick={() => { handleEnablePassword(props) }}
                    loading={loading}
                    variant="primary"

                    fullWidth
                  >
                    {props.values.passwordEnable ? "Cancelar alteração de Senha" : "Alterar Senha"}
                  </Button>
                  </div>
                }
                {
                  props.values.passwordEnable &&
                  <Field
                    type="password"
                    autoComplete="sula-call-center-password"
                    name="password"
                    label="Senha"
                    component={TextInput}
                  />
                }
                {
                  props.values.passwordEnable &&
                  <Field
                    type="password"
                    autoComplete=""
                    name="passwordConfirmation"
                    label="Confirme sua Senha"
                    component={TextInput}
                  />
                }

                { error && <div className="form__error">{error}</div>}

                <ButtonsContainer>
                  <Button
                    onClick={(values) => { props.handleSubmit(values, edit) }}
                    loading={loading}
                    variant="primary"
                    fullWidth
                  >
                    {edit ? "Editar" : "Cadastrar"}
                  </Button>
                </ButtonsContainer>
              </Form>
            )
          }}
        </Formik>
      </div>
    </>
  )
}

export default UserModal;
