import { call, put } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import axios from 'axios'

import { serviceUrl, returnResponseMessage } from '@valid/config'
import { AUTH_SUCCESS, AUTH_FAILURE } from 'store/reducers/auth'
import { USER_REQUEST } from 'store/reducers/user'
import { persistor } from 'store'
import { TOKEN_REQUEST, setToken } from 'store/reducers/token'


function axiosPost(params) {
  return axios({
    method: 'POST',
    url: `${serviceUrl[process.env.REACT_APP_ENV]}${params.url}`,
    headers: { 'Content-Type': 'application/json' },
    data: params.payload,
  })
}

export function* handleLogin(action) {
  try {
    const { username, password } = action.payload

    const res = yield call(axiosPost, {
      url: '/user/auth',
      payload: { username, password },
      headers: {
        'Content-Type': 'application/json',
      }
    })

    console.log({ res })
    const { access_token } = res.data

    if (res) {
      yield put({
        type: USER_REQUEST,
        payload: res.data,
      })

      yield put({
        type: AUTH_SUCCESS,
      })
      yield put({
        type: TOKEN_REQUEST,
        payload: access_token
      })
      yield put(push('/dashboard'))
    }
  } catch (e) {
    yield put({
      type: AUTH_FAILURE,
      payload: returnResponseMessage('auth', 'login', e.response.status),
    })
  }
}

export function* handleLogout() {
  yield put(setToken(''))
  persistor.purge()
  yield put(push('/login'))
}
