import styled from 'styled-components'
import { motion } from 'framer-motion'
import { Dialog } from '@material-ui/core'

export default styled(motion.div)`
  .validations {
    list-style: none;
    padding: 0;
    margin: 0;
    margin-top: 4rem;

    li {
      border-bottom: 1px solid ${(props) => props.theme.palette.gray[200]};

      &:first-child {
        border-top: 1px solid ${(props) => props.theme.palette.gray[200]};
      }

      button {
        background-color: transparent;
        border: 0;
        outline: none;
        font-size: 1.6rem;
        padding: 3rem 2rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        font-weight: 600;
        color: ${(props) => props.theme.palette.gray[500]};
        border: 2px solid transparent;
        border-radius: 0.8rem;
        transition: all 0.2s ease-in-out;

        img {
          width: 4rem;
        }

        &.selected {
          border-color: ${(props) => props.theme.palette.primary};
          font-weight: 700;
          color: ${(props) => props.theme.palette.primary};
        }
      }
    }
  }
`

export const Modal = styled(Dialog)`
  .MuiDialog-paper {
    max-width: 90%;
    padding: 2rem;
    border-radius: 1.6rem;

    h2 {
      font-weight: 700;
      font-size: 2.6rem;
      text-transform: uppercase;
      width: 100%;
      text-align: center;
      margin-bottom: 2rem;
      color: #fcba03;
    }

    h4 {
      font-size: 1.2rem;
      margin-bottom: 1rem;
      text-align: center;
    }

    .tip {
      border-collapse: collapse;
      font-size: 1.4rem;
      margin-top: 2rem;

      tbody {
        tr {
          &:not(:last-child) {
            border-bottom: 1px solid ${(props) => props.theme.palette.gray[200]};
          }

          td {
            padding: 1.5rem 0;

            svg {
              width: 2.4rem;
              height: 2.4rem;
              margin-right: 1rem;
              color: #fcba03;
            }
          }
        }
      }
    }
  }

  .text_button {
    background-color: transparent;
    border: 0;
    outline: none;
    color: ${(props) => props.theme.palette.primary};
    font-weight: 600;
    font-size: 1.2rem;
    padding: 2rem;
    margin-top: 1rem;
  }
  `

  export const UploadContainer = styled(motion.div)`
  .title {
    font-size: 2.8rem;
    width: 100%;
    text-align: center;
    font-weight: 700;
    margin-bottom: 1rem;
  }

  .subtitle {
    color: ${(props) => props.theme.palette.gray[500]};
    font-size: 1.4rem;
    font-weight: 500;
    margin-bottom: 1rem;
    text-align: center;
  }

  .doc_model {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2rem 0;

    img {
      width: 22rem;
    }
  }

  .error {
    color: #e31010;
    margin: 2rem;
    font-size: 1.4rem;
    text-align: center;
    font-weight: 600;
  }

  .file_container {
    &__label {
      background-color: ${(props) => props.theme.palette.primary};
      color: white;
      font-weight: 600;
      text-transform: uppercase;
      border-radius: 1.6rem;
      font-size: 1.4rem;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 5rem;
      margin: 2rem;
      margin-top: 6rem;
    }

    &__input {
      display: none;
    }
  }
`

export const PreviewContainer = styled(motion.div)`
  padding: 2rem;

  .title {
    width: 100%;
    text-align: center;
    font-weight: 700;
    font-size: 2rem;
  }

  img {
    width: 100%;
    border-radius: 0.8rem;
    margin: 2rem 0;
  }

  .question {
    font-size: 1.4rem;
    font-weight: 600;
    margin-bottom: 1rem;
  }

  .buttons_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .no_button {
    background-color: ${(props) => props.theme.palette.gray[300]};
    color: ${(props) => props.theme.palette.gray[800]};
    border: 0;
    border-radius: 0.4rem;
    outline: none;
    cursor: pointer;
    font-size: 1.4rem;
    text-transform: uppercase;
    font-weight: 600;
    padding: 0 2rem;
    transition: all 0.3s ease;
    height: 4.2rem;
    flex: 1;
    margin-right: 2rem;
  }

  .yes_button {
    background-color: ${(props) => props.theme.palette.primary};
    color: ${(props) => props.theme.palette.contrast};
    border: 0;
    border-radius: 0.4rem;
    outline: none;
    cursor: pointer;
    font-size: 1.4rem;
    text-transform: uppercase;
    font-weight: 600;
    padding: 0 2rem;
    transition: all 0.3s ease;
    height: 4.2rem;
    flex: 1;
  }

  .loading {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.8);

    &__container {
      background-color: white;
      padding: 1.5rem;
      border-radius: 50%;

      svg {
        height: 4rem;
        width: 4rem;
      }
    }
  }
`
export const FailContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  h1 {
    font-size: 2.4rem;
    color: ${(props) => props.theme.palette.primary};
    margin-bottom: 2rem;
  }

  h4 {
    font-size: 1.6rem;
    font-weight: 600;
    color: ${(props) => props.theme.palette.gray[600]};
    margin-bottom: 2rem;
  }
  .tip {
    border-collapse: collapse;
    font-size: 1.4rem;
    margin-top: 2rem;
    margin-bottom: 1rem;

    tbody {
      tr {
        &:not(:last-child) {
          border-bottom: 1px solid ${(props) => props.theme.palette.gray[200]};
        }

        td {
          padding: 1.5rem 0;

          svg {
            width: 2.4rem;
            height: 2.4rem;
            margin-right: 1rem;
            color: #fcba03;
          }
        }
      }
    }
  }
  .hint {
    border: 1px solid ${(props) => props.theme.palette.gray[200]};
    border-radius: 0.8rem;
    padding: 1rem;
    margin: 0 2rem;
    margin-bottom: 4rem;

    svg {
      width: 2.4rem;
      height: 2.4rem;
      margin-bottom: 1rem;
      color: #fcba03;
    }

    p {
      font-size: 1.4rem;
    }
  }
`
