import React, { useEffect, useState } from 'react'
import { motion } from 'framer-motion'
import { useSelector, useDispatch } from 'react-redux'
import { CircularProgress } from '@material-ui/core'
import UserModal from '../UserModal';
import { toast } from 'react-toastify'

import Container, { RowButton } from './styles'
import {
  Table,
  TableContainer,
  Pagination,
  Icons,
  Title,
  Button,
  SearchBar
} from '@valid/components'
import { getUsers } from 'store/reducers/user'
import { maskCpfCnpj } from '@valid/config'
import { get, post, apiPut, apiDelete } from 'service/api'
import { avatar } from 'assets/default_avatar.jpg'


function levels(level) {
  if (level === 'admin') {
    return 'Administrador'
  } else if (level === 'manager') {
    return 'Gestor de Operação'
  } else {
    return 'Analista de Operação'
  }
}


export default function () {
  const { token } = useSelector((state) => state.token)
  const dispatch = useDispatch()
  const [page, setPage] = useState(1)
  const [search, setSearch] = useState('')
  const [pages, setPages] = useState(1)
  const [userModalOpen, setUserModalOpen] = useState(false)
  const [currentUser, setCurrentUser] = useState()

  const [list, setList] = useState([])
  const [loading, setLoading] = useState(true)
  const [isCreate, setIsCreate] = useState(true)


  const handleUserPage = async (page, search) => {
    setLoading(true)
    setList([])
    const res = await get({
      url: `/users/${page}?search=${search}`,
      token,
    })

    if (res.status === 200) {
      const { total_pages, items } = res.data
      setList(items)
      setPages(total_pages)
    }

    setLoading(false)
  }

  useEffect(() => {
    handleUserPage(page, search)
  }, [page, search])

  function handlePageChange(_, number) {
    setPage(number)
  }

  function returnColorComponent(color) {
    if (color === "green") return <Icons icon="check" />
    else if (color === "yellow") return <Icons icon="info" />
    else if (color === "red") return <Icons icon="error" />
    else return null
  }

  function openModal() {
    setCurrentUser(null)
    setUserModalOpen(true)
    setIsCreate(true)
  }

  const deleteUser = async (_id) => {
    let res = null
    if (_id) {
      res = await apiDelete({
        url: `/user/${_id}`,
        token,
      })
    }
    if (res) {
      if (res.status === 200) {
        handleUserPage(page, search)
        toast.success('Usuário excluído com sucesso')
      }
      else {
        toast.error(res.data.message)
      }
    }
  }

  const handleSubmit = async (user, edit) => {
    let res = null
    if (edit) {
      res = await apiPut({
        url: `/user/${user._id}`,
        token,
        data: user
      })
    }
    else {
      res = await post({
        url: '/user',
        token,
        data: user
      })

    }
    if (res) {
      if (res.status === 200) {
        handleUserPage(page, search)
        setUserModalOpen(false)
        if (edit) {
          toast.success('Usuário editado com sucesso')
        }
        else {
          toast.success('Usuário criado com sucesso')
        }
        window.location.reload();
      }
      else {
        toast.error(res.data.message)
      }
    }
  }

  async function handleSearch(value) {
    setSearch(value)
    handleUserPage(page, search)
  }

  function handleCloseModal() {
    setUserModalOpen(false)
  }

  return (
    <Container>
      <div className="float-right-position">
        <Button
          variant="primary"
          onClick={openModal}>
          Criar
        </Button>
      </div>

      <motion.div
        initial={{ opacity: 0, x: 30 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.4 }}
      >
        <Title>Gerenciar usuários</Title>
      </motion.div>


      <div class="search">
        <SearchBar
            placeholder="Pesquisar"
            onSearch={handleSearch}
        />
      </div>


      <motion.div
        initial={{ opacity: 0, y: 30 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.4 }}
      >
        <TableContainer>
          <Table>
            <thead>
              <tr>
                <th>Nome</th>
                <th>E-mail</th>
                <th>Tipo</th>
                <th>Editar</th>
                <th>Excluir</th>
              </tr>
            </thead>
            <tbody>
              {list.length === 0 &&
                <tr key={"-1"}>
                  <td colSpan={8}>Nenhum registro encontrado.</td>
                </tr>
              }
              {list.map((item) => (
                <tr key={item._id}>
                  <td>
                    {item.name}
                  </td>
                  <td>
                    {item.email}
                  </td>
                  <td>
                    {levels(item.level)}
                  </td>
                  <td>
                    <Button onClick={(e) => {
                      setCurrentUser(item)
                      setUserModalOpen(true)
                      setIsCreate(false)
                    }}>
                      <div className="icon">
                        <Icons icon="edit" />
                      </div>
                    </Button>
                  </td>
                  <td>
                    <Button onClick={(e) => {
                      setCurrentUser(item)
                      deleteUser(item._id)
                    }}>
                      <div className="icon">
                        <Icons icon="delete" />
                      </div>
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </TableContainer>

        <Pagination pages={pages} onChange={handlePageChange} />
      </motion.div>

      <UserModal isModalOpen={userModalOpen}
        onModalClose={() => { setUserModalOpen(false) }}
        handleSubmit={handleSubmit}
        edit={currentUser ? true : false}
        user={currentUser}
        enable={isCreate ? true : false} />

      {loading && (
        <div className="loading">
          <div className="loading__container">
            <CircularProgress />
          </div>
        </div>
      )}
    </Container>
  )
}
