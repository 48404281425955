
export const TOKEN_REQUEST = 'TOKEN_REQUEST'

const initialState = {
  token: '',
}

export default function token(state = initialState, { type, payload }) {
  switch (type) {
    case TOKEN_REQUEST:
      return {
        token: payload,
      }
    default:
      return state
  }
}

export function setToken(payload) {
  return {
    type: TOKEN_REQUEST,
    payload,
  }
}
