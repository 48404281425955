import React, { useState } from 'react'
import { Formik, Field, Form } from 'formik'
import * as Yup from 'yup'
import { useSelector, useDispatch } from 'react-redux'
import { motion } from 'framer-motion'
import { get } from 'service/api'
import { toast } from 'react-toastify'

import Container from './styles'
import { validationMessages } from '@valid/config'
import { Button, ButtonsContainer, TextInput, OnboardTitle } from '@valid/components'
import {CircularProgress} from '@material-ui/core'

const validationSchema = Yup.object({
  Email: Yup.string().required(validationMessages.required),
})

const containerVariants = {
  initial: { y: 0, opacity: 0 },
  formInitial: { y: -40, opacity: 0 },
  formAnimate: { y: 0, opacity: 1 },
}

export default function ({ location, history }) {
  const token = useSelector((state) => state.token)
  const [emailData] = useState({email: ''})
  const [loading, setLoading] = useState(false)
  const [recoverRequested, setRecoverRequested] = useState(false)
  const { state } = location

  const isLogged = token.length > 0

  if (isLogged) {
    history.replace('/dashboard')
  }
  
  const handleBack = async () => {
    history.replace({ pathname: `/login`})
  }
  
  async function handleSubmit(values) {
    const { email } = values
    setLoading(true)

    let success = false

    try {
        const res = await get({
            url: `/passwords/recover?email=${email}`,
            token: false
        })
        
        setLoading(false) 

        console.log(res.data.message)

        if (res.status === 200) {
          setRecoverRequested(true)
        } else if (res.status === 400){
          setRecoverRequested(false)
          toast.error('Email inválido ou não cadastrado')
        } else {
          toast.error('Não foi possível solicitar recuperação de senha')
        }

    } catch (error) {
      setLoading(false)
      toast.error('Não foi possível solicitar recuperação de senha')
    }
  }

  return (
    <motion.div
      variants={containerVariants}
      initial={state && state.fromForgot ? 'formInitial' : 'initial'}
      animate="formAnimate"
      transition={{ duration: 0.4 }}
    >
      <Container>

          <Formik
            initialValues={emailData}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >

            {(props) => {
              return !recoverRequested ? (
                <Form
                  className="form"
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      handleSubmit(props.values)
                    }
                  }}
                >
                  <Field
                    name="email"
                    label="Insira o E-mail ao qual quer recuperar a senha"
                    placeholder="Email"
                    component={TextInput}
                  />

                  <ButtonsContainer>
                    <Button
                      onClick={() => handleSubmit(props.values)}
                      variant="primary"
                      fullWidth
                    >
                      Recuperar
                    </Button>
                    <Button
                      onClick={handleBack}
                      variant="primary"
                      fullWidth
                    >
                      Voltar a tela de login
                    </Button>
                  </ButtonsContainer>
                </Form>
              ) : (<>
                <div class="message">
                  O link para criar uma nova senha foi enviado <br/> 
                  para o email {props.values.email}
                </div>

                <ButtonsContainer>
                    <Button
                      onClick={handleBack}
                      variant="primary"
                      fullWidth
                    >
                      Voltar a tela de login
                    </Button>
                </ButtonsContainer>
            </>) 
          }}
          </Formik>

          {loading && (
          <div className="loading">
            <div className="loading__container">
              <CircularProgress />
            </div>
          </div>
          )}

      </Container>
    </motion.div>
  )
}
