import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'

import token from './token'
import desktop from './desktop'
import quiz from './quiz'
import auth from './auth'
import dashboard from './dashboard'
import link from './link'
import user from './user'

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    token,
    desktop,
    quiz,
    auth,
    dashboard,
    link,
    user
  })
