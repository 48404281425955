import React, { useState, useEffect } from 'react'
import { Route } from 'react-router-dom'
import { detect } from 'detect-browser'
import { motion } from 'framer-motion'

import Container from './styles'

//const logo = `assets/${process.env.REACT_APP_THEME}/logo.png`
const logo = "https://static-images-inovamind.s3.us-east-1.amazonaws.com/sa/logo.png"
const browser = detect()

export default ({ component: Component, ...rest }) => {
  const [isDesktop, setIsDesktop] = useState(false)

  useEffect(() => {
    if (browser.os !== 'iOS' && browser.os !== 'Android OS') {
      setIsDesktop(true)
    }
  }, [])

  return (
    <Route
      {...rest}
      render={(matchProps) => (
        <Container>
          <div className={isDesktop ? 'mobile_container' : 'full_width'}>
            <motion.header
              className="header"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.4 }}
            >
              <img src={logo} alt="" className="header__logo" />
            </motion.header>
            <motion.main
              className="main"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.4 }}
            >
              <Component {...matchProps} />
            </motion.main>
          </div>
        </Container>
      )}
    />
  )
}
