import jwtDecode from 'jwt-decode'
import { toast } from 'react-toastify'

import { serviceUrl } from '@valid/config'
import { history } from 'store'

import axios from './axiosCustom'

export const get = async ({ url, token, responseType }) => {
  return await service('GET', url, token, responseType)
}

export const post = async ({ url, token, data }) => {
  return await service('POST', url, token, data, null)
}

export const apiPut = async ({ url, token, data }) => {
  return await service('put', url, token, data, null)
}

export const apiDelete = async ({ url, token }) => {
  return await service('delete', url, token, null)
}

const tokenExpired = (token) => {
  const decoded = jwtDecode(token)
  const current_time = Date.now() / 1000

  if (decoded.exp < current_time) {
    return true
  }
}

const request = async (method, url, token = null, data = null, responseType = null) => {
  try {
    let headers = {'Content-Type': 'application/json'}
    if(token){
      headers['Authorization'] = 'Bearer ' + token
    }

    console.log(headers)

    const res = await axios({
      method: method,
      url: `${serviceUrl[process.env.REACT_APP_ENV]}${url}`,
      headers,
      data,
      responseType: responseType || null
    })
    return {
      status: res.status,
      data: res.data,
    }
  } catch (e) {
    return {
      status: e.response.status,
      data: e.response.data,
    }
  }

}

const service = async (method, url, token = null, data = null) => {
  
  console.log(method, url, token, data)

  if (!token && token !== false) {
    toast.error('Acesso não autorizado!')
    history.replace('/')
  }

  if (token == false) {
    return await request(method, url, null, data)
  } else {

    if(tokenExpired(token)){
      toast.error('Seu acesso expirou.')
      return history.replace('/')
    } else {
      return await request(method, url, token, data)
    }

  }   
}

export const postFile = async ({ url, token, data }) => {
  return await serviceFile('POST', url, token, data)
}

export const putFile = async ({ url, token, data }) => {
  return await serviceFile('PUT', url, token, data)
}

export const serviceFile = async (method, url, token, data) => {
  if (token) {
    const decoded = jwtDecode(token)
    const current_time = Date.now() / 1000

    if (decoded.exp < current_time) {
      toast.error('Seu acesso expirou.')
      return history.replace('/')
    } else {
      try {
        const res = await axios({
          method,
          url: `${serviceUrl[process.env.REACT_APP_ENV]}${url}`,
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: 'Bearer ' + token,
          },
          data,
        })
        return {
          status: res.status,
          data: res.data,
        }
      } catch (e) {
        return {
          status: e.response.status,
          detail: e.response.data.detail,
        }
      }
    }
  } else {
    toast.error('Token de acesso inválido')
  }
}
