import React, { useState, useEffect, useCallback } from 'react'

import Container from './styles'
import { OnboardTitle, AnimatedCheck } from '@valid/components'

export default function ({ location }) {
  const [redirectTime, setRedirectTime] = useState(5)

  const { state } = location

  const handleRedirectTime = useCallback(() => {
    if (redirectTime === 0) {
      window.location.href = state.redirect
    } else {
      setTimeout(() => {
        setRedirectTime(redirectTime - 1)
      }, 1000)
    }
  }, [redirectTime, state.redirect])

  useEffect(() => {
    handleRedirectTime()
  }, [handleRedirectTime])

  return (
    <Container
      initial={{ opacity: 0, x: 20 }}
      animate={{ opacity: 1, x: 0 }}
      transition={{ duration: 0.4 }}
    >
      <OnboardTitle>Terminamos o processo!</OnboardTitle>

      <div className="success">
        <AnimatedCheck />
      </div>

      <p className="text">Você será redirecionado em</p>
      <p className="counter">{redirectTime}</p>
    </Container>
  )
}
