import styled from 'styled-components'

export default styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  .icon {
    svg {
      width: 10rem;
      height: 10rem;
      color: #e31010;
      margin-bottom: 2rem;
    }
  }

  h1 {
    font-size: 2.4rem;
    margin-bottom: 4rem;
    color: #3c3c3c;
  }

  .text {
    font-size: 1.4rem;
    font-weight: 600;
    margin-bottom: 1rem;
    color: ${(props) => props.theme.palette.gray[500]};
  }

  .counter {
    font-size: 2rem;
    font-weight: 600;
    color: ${(props) => props.theme.palette.gray[700]};
  }
`
