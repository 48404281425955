import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'

import Container, { ButtonContainer } from './styles'

export const ButtonsContainer = ButtonContainer

export default function ({ children, fullWidth, variant, loading, onClick, style }) {
  return (
    <Container
      type="button"
      className={`${fullWidth && !loading ? 'full_width' : ''} ${variant && !loading ? variant : ''
        } ${loading ? 'loading' : ''}`}
      onClick={!loading ? onClick : null}
    >
      {loading ? <CircularProgress className="loader" /> : children}
    </Container>
  )
}
