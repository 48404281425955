import styled from 'styled-components'
import { motion } from 'framer-motion'

export default styled(motion.div)`
  .form {
    section {
      margin: 4rem 0;
    }

    .error {
      text-align: center;
      font-size: 1.2rem;
      font-weight: 600;
      color: #e31010;
      margin-bottom: 4rem;
    }
  }

  .loading {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.8);

    &__container {
      background-color: white;
      padding: 1.5rem;
      border-radius: 50%;

      svg {
        height: 4rem;
        width: 4rem;
      }
    }
`
