import styled from 'styled-components'

export default styled.div`

  .message {
    color: ${(props) => props.theme.palette.gray[900]};
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 1rem;
    text-align: center;
  }

  .form {
    width: 35rem;
    color: #1d2d72;

    &__link_container {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 2rem;
    }

    &__link {
      color: ${(props) => props.theme.palette.primary};
      font-size: 1.1rem;
      text-transform: uppercase;
      text-decoration: none;
      background-color: transparent;
      border: none;
      outline: none;
      cursor: pointer;
    }

    &__error {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 1.5rem;
      font-size: 1.2rem;
      font-weight: 600;
      color: ${(props) => props.theme.palette.error};
    }
  }

  .loading {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.8);

    &__container {
      background-color: white;
      padding: 1.5rem;
      border-radius: 50%;

      svg {
        height: 4rem;
        width: 4rem;
      }
    }
`
