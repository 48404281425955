import { all, takeLatest } from 'redux-saga/effects'

import { QUIZ_REQUEST, QUIZ_RETRY_REQUEST, QUIZ_SUBMIT, QUIZ_TIMEOUT_SUBMIT } from 'store/reducers/quiz'
import { AUTH_REQUEST } from 'store/reducers/auth'
import { USER_LOGOUT } from 'store/reducers/user'
import { LINK_REQUEST } from 'store/reducers/link'
import { DASHBOARD_REQUEST } from 'store/reducers/dashboard'
import { USER_LIST_REQUEST } from 'store/reducers/user'

import { handleLogin, handleLogout } from './auth'
import { handleGenerateLink } from './link'
import { handleFetchPage } from './dashboard'
import { handleUserPage } from './user'

import { handleFetchQuestions, handleSubmitAnswers, handleRetry, handleTimeout } from './quiz'

export default function* root() {
  yield all([
    takeLatest(QUIZ_REQUEST, handleFetchQuestions),
    takeLatest(QUIZ_SUBMIT, handleSubmitAnswers),
    takeLatest(QUIZ_TIMEOUT_SUBMIT, handleTimeout),
    takeLatest(QUIZ_RETRY_REQUEST, handleRetry),
    takeLatest(AUTH_REQUEST, handleLogin),
    takeLatest(USER_LOGOUT, handleLogout),
    takeLatest(LINK_REQUEST, handleGenerateLink),
    takeLatest(DASHBOARD_REQUEST, handleFetchPage),
  ])
}
