import styled from 'styled-components'
import { Dialog } from '@material-ui/core'

export default styled.div`
  margin-top: 1rem;

  .search {
    width: 100%;
    padding: 10px 60% 10px 0; 
    text-align: left;
  }

  .loading {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.8);

    &__container {
      background-color: white;
      padding: 1.5rem;
      border-radius: 50%;

      svg {
        height: 4rem;
        width: 4rem;
      }
    }
  }

  .float-right-position {
    float: right;
    margin-top: 2rem;
  }
`

export const RowButton = styled.button`
  background-color: ${(props) => props.theme.palette.gray[200]};
  border: 0;
  border-radius: 0.8rem;
  outline: none;
  padding: 1rem;
  cursor: pointer;
  font-size: 1rem;

  display: block;

  &:not(:first-child) {
    margin-left: 2rem;
  }

  &:only-child {
    margin: auto;
  }

  &:hover {
    background-color: ${(props) => props.theme.palette.gray[300]};
  }

  svg {
    margin: 0 !important;
  }

  span {
    display: block;
    margin-top: 0.2rem;
  }
`

export const DocumentModal = styled(Dialog)`
  .MuiDialog-paper {
    max-width: 90%;
    padding: 2rem;
    border-radius: 1.6rem;

    .modal_document {
      display: block;
      max-height: 60rem;
      width: auto;
      height: auto;
      background-color: ${(props) => props.theme.palette.gray[200]};
    }
  }
`
