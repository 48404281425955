import React from 'react'
import jwtDecode from 'jwt-decode'
import { toast } from 'react-toastify'
import { useSelector, useDispatch } from 'react-redux'

import { logout } from 'store/reducers/user'
import { setToken } from 'store/reducers/token'
import { useRouter } from '@valid/config'

export default function ({ parent: Parent, child, history, level, ...rest }) {
  const { token } = useSelector((state) => state.token)
  const dispatch = useDispatch()
  const router = useRouter()

  function returnToLogin() {
    router.history.push({
      pathname: '/',
      state: { from: router.location.pathname },
    })
  }

  // return < Parent component={child} {...rest} />
  if (token) {
    const decoded = jwtDecode(token)
    if (level) {
      if ( !level.includes(decoded.identity.level)) {
        toast.error('Acesso não autorizado!')
        returnToLogin()
      }
    }
    const current_time = Date.now() / 1000
    if (decoded.exp < current_time) {
      toast.warn('Seu acesso expirou, faça login novamente.')
      dispatch(setToken(''))
      dispatch(logout())
      returnToLogin()
      return null
    }

    return <Parent component={child} {...rest} />
  }

  toast.error('Acesso não autorizado!')
  returnToLogin()
  return null
}
